import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import '../../assets/styles/reset.css';
import '../../assets/styles/styles.css';
import '../../assets/less/styles.less';
import '../../assets/styles/datepicker.css';
import * as Actions from '../../actions/actions';
import Spinner from '../../components/spinner/Spinner';
import HereMapComponent from '../../components/HereMapComponent';
import MessageStack from '../../components/MessageStack';

class App extends Component {
    componentWillMount() {
        this.props.getConfig();
        this.isCharity = this.props.location.pathname === '/charity-rides';
    }

    render() {
        if (this.props.state.configs.loaded) {
            return (
                <div>
                    <Spinner visible={this.props.state.spinner}/>
                    <div className="map-container">
                        <HereMapComponent
                            isCharity={this.isCharity}
                            startSpinner={this.props.startSpinner}
                            stopSpinner={this.props.stopSpinner}
                            addMessage={this.props.addMessage}
                            clearMessages={this.props.clearMessages}
                            updateRouteSummary={this.props.updateRouteSummary}
                            selectPoint={this.props.selectPoint}
                            selectPointByCoordinates={this.props.selectPointByCoordinates}
                            registerRoute={this.props.registerRoute}
                            onMapLoaded={this.props.onMapLoaded}
                            configs={this.props.state.configs}
                            load={this.props.state.load.load}
                            points={this.props.state.points.points}
                            selectedPoint={this.props.state.points.selectedPoint}
                            pointsStepMins={this.props.state.points.stepMins}
                            minimumRouteTimeGap={this.props.state.points.minimumRouteTimeGap}
                            location={this.props.location}
                            timeZone={this.props.state.load.load && this.props.state.load.load.pickupTimeZone}
                        />
                    </div>
                    <div className="control-panel-container">
                        {this.props.children}
                    </div>
                    <MessageStack messages={this.props.state.messages}/>
                </div>
            );
        } else {
            return (<div/>);
        }
    }
}

App.propTypes = {
    getConfig: PropTypes.func.isRequired,
    updateRouteSummary: PropTypes.func.isRequired,
    startSpinner: PropTypes.func.isRequired,
    stopSpinner: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired,
    clearMessages: PropTypes.func.isRequired,
    selectPoint: PropTypes.func.isRequired,
    selectPointByCoordinates: PropTypes.func.isRequired,
    registerRoute: PropTypes.func.isRequired,
    onMapLoaded: PropTypes.func.isRequired,

    children: PropTypes.node,
    state: PropTypes.object.isRequired,
    location: PropTypes.object,
    intl: PropTypes.object
};

export default connect(state => {
    return {
        state: state
    };
}, dispatch => {
    return bindActionCreators({...Actions}, dispatch);
})(injectIntl(App));
