let registrationId = 0;
let registrations = {};

export function stopEvent(e) {
    if(e.stopPropagation) e.stopPropagation();
    if(e.preventDefault) e.preventDefault();
    e.cancelBubble=true;
    e.returnValue=false;
    return false;
}

export function bindEvent(el, eventType, func) {
    el.addEventListener(eventType, func);
    if (registrationId == Number.MAX_VALUE) { // eslint-disable-line
        registrationId = 0;
    }
    registrationId++;
    registrations[registrationId] = {
        element: el,
        eventType: eventType,
        func: func
    };
    return [registrationId];
}

export function unbindEvent(registrationIds) {
    if (!registrationIds) {
        return;
    }
    for (let i=0; i<registrationIds.length; i++) {
        const registrationId = registrationIds[i];
        const val = registrations[registrationId];
        if (val) {
            val.element.removeEventListener(val.eventType, val.func);
            delete registrations[registrationId];
        }
    }
}

export function bindWindowResizeEvent(func) {
    return bindEvent(window, 'resize', func);
}
