import { GET_LOAD_INFO,
    startedAction, failedAction, successfulAction } from '../actions/actionTypes';

const initialState = {
    loading: true
};

export default function load(state = initialState, action) {
    switch (action.type) {
        case startedAction(GET_LOAD_INFO):
            return Object.assign({}, state, {loading: true});
        case failedAction(GET_LOAD_INFO):
            return Object.assign({}, state, {loading: false});
        case successfulAction(GET_LOAD_INFO):
            return Object.assign({}, state, {
                load: action.payload.bookingInfo,
                loading: false
            });
        default:
            return state;
    }
}
