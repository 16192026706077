import * as types from './actionTypes';


export function getPoints(parameters, minimumRouteTimeGap) {
    return {
        type: types.GET_POINTS,
        payload: parameters,
        dispatchParams: {minimumRouteTimeGap}
    };
}

export function getLoadInfo(loadId, debug) {
    return {
        type: types.GET_LOAD_INFO,
        payload: {loadId, debug}
    };
}

export function getPdfLoadReport(load, loadId, dispatchParams) {
    return {
        type: (dispatchParams.print
                ? types.GET_PDF_LOAD_REPORT_PRINT
                : types.GET_PDF_LOAD_REPORT),
        payload: {
            load,
            loadId
        },
        dispatchParams
    };
}

export function mailPdfLoadReport(load, loadId, callback) {
    return {
        type: types.MAIL_PDF_LOAD_REPORT,
        payload: {
            load,
            loadId
        },
        dispatchParams: {
            callback
        }
    };
}

export function getPdfMyDayReport(payload, dispatchParams) {
    return {
        type: (dispatchParams.print
                ? types.GET_PDF_MY_DAY_REPORT_PRINT
                : types.GET_PDF_MY_DAY_REPORT),
        payload,
        dispatchParams
    };
}
export function mailPdfMyDayReport(payload, callback) {
    return {
        type: types.MAIL_PDF_MY_DAY_REPORT,
        payload,
        dispatchParams: {
            callback
        }
    };
}

export function selectPoint(point, selectionSource) {
    return {
        type: types.SELECT_POINT,
        payload: {point, selectionSource}
    };
}

export function selectPointByCoordinates(point) {
    return {
        type: types.SELECT_POINT_BY_COORDINATES,
        payload: point
    };
}

export function getConfig(successCallback) {
    return {
        type: types.GET_CONFIG,
        payload: {
            successCallback
        }
    };
}

export function updateRouteSummary(length, time) {
    return {
        type: types.UPDATE_ROUTE_SUMMARY,
        payload: {
            length,
            time
        }
    };
}

export function onMapLoaded() {
    return {
        type: types.MAP_LOADED
    };
}


export function addMessage(payload) {
    return {
        type: types.ADD_MESSAGE,
        payload
    };
}

export function clearMessages() {
    return {
        type: types.CLEAR_MESSAGES
    };
}


export function startSpinner() {
    return {
        type: types.startedAction(types.SPINNER_ACTION)
    };
}

export function stopSpinner() {
    return {
        type: types.successfulAction(types.SPINNER_ACTION)
    };
}


export function registerRoute(polyline, color, queryTime) {
    return {
        type: types.REGISTER_ROUTE,
        payload: {
            polyline,
            color,
            queryTime
        }
    };
}

export function getCharityPoints(parameters, minimumRouteTimeGap) {
    return {
        type: types.GET_CHARITY_POINTS,
        payload: parameters,
        dispatchParams: {minimumRouteTimeGap}
    };
}

export function getCharityUsers() {
    return {
        type: types.GET_CHARITY_USERS,
        payload: {
            region: 'UK',
        },
    };
}
