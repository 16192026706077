import React, {Component} from "react";
import PropTypes from 'prop-types';
import {FormattedHTMLMessage, injectIntl} from 'react-intl';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import moment from 'moment-timezone';
import queryString from 'query-string';
import * as Actions from '../../actions/actions';
import {GET_PDF_LOAD_REPORT, GET_PDF_LOAD_REPORT_PRINT, MAIL_PDF_LOAD_REPORT}  from '../../actions/actionTypes';

import '../../assets/styles/datepicker.css';
import RouteSummary from "../../components/RouteSummary";
import LocationHistory from "../../components/LocationHistory";
import ControlPanelAddendum from "../../components/ControlPanelAddendum";
import {isIE} from '../../utils/browserUtils';
import deliveryImage from '../../assets/images/summary_delivery.svg';
import pickupImage from '../../assets/images/summary_pickup.svg';
import {getStopAddress} from '../../utils/stopUtils'


class Load extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = this.getState(props);
    }

    componentWillMount() {
        this.query = queryString.parse(this.props.location.search);
        this.props.getLoadInfo(this.query.loadId, this.query.debug);
    }

    getState(props) {
        return {
            activeTab: 'LOCATION_HISTORY'
        };
    }

    renderStop(stop) {
        return (<div key={'stop' + stop.stopOrder} className="loadSummary-point">
            <div className="loadSummary-decoration">
                {stop.type === 'PICKUP'
                    ? <img src={pickupImage} width={9} height={9} alt="pickup" />
                    : <img src={deliveryImage} width={12} height={12} alt="delivery" />
                }
                <div className="loadSummary-spacer" />
            </div>
            <div className="loadSummary-town">{`${this.props.intl.messages['stop.type.' + stop.type]} | ${getStopAddress(stop)}`}</div>
        </div>);
    }

    render() {
        const points = this.props.state.points.points;
        const load = this.props.state.load.load;
        let stops;
        if (load && load.stops) {
            stops = [...load.stops]
                .sort((s1, s2) => s1.stopOrder - s2.stopOrder)
                .map(stop => this.renderStop(stop));
        } else {
            stops = [];
        }
        return (
            <>
                <div className={"control-panel" + (isIE() ? " control-panel-ie-fix" : "")}>
                    <div className="panel-title">
                        <FormattedHTMLMessage id="load.title" values={{loadId: this.query.loadId}}/>
                    </div>
                    {!!this.props.state.load.load &&
                    <div className="loadSummary">
                        <div className="loadSummary-point">
                            <div className="loadSummary-decoration">
                                <img src={pickupImage} width={12} height={12} alt="pickup" />
                                <div className="loadSummary-spacer"/>
                            </div>
                            <div className="loadSummary-town">{load.fromDisplayAddress}</div>
                        </div>
                        {stops}
                        <div className="loadSummary-point">
                            <div className="loadSummary-decoration">
                                <img src={deliveryImage} width={15} height={15} alt="delivery" />
                            </div>
                            <div className="loadSummary-town">{load.toDisplayAddress}</div>
                        </div>
                    </div>
                    }
                    <RouteSummary routes={this.props.state.routes} intl={this.props.intl} countryCode={load && load.from.country} />
                    <div className="tab-container">
                        <button onClick={() => this.setState({activeTab: 'BOOKING_STATUS'})} className={'BOOKING_STATUS' === this.state.activeTab ? 'tab-active' : 'tab'}>
                            <span>{this.props.intl.messages['load.bookingStatus.title']}</span>
                        </button>
                        <button onClick={() => this.setState({activeTab: 'LOCATION_HISTORY'})} className={'LOCATION_HISTORY' === this.state.activeTab ? 'tab-active' : 'tab'}>
                            <span>{this.props.intl.messages['load.locationHistory.title']}</span>
                        </button>
                    </div>
                    {'BOOKING_STATUS' === this.state.activeTab && (this.props.state.load.load
                            ? <div className="loadSummary-bookingStatuses">
                                {this.renderEventSummary(load)}
                            </div>
                            : <div className="noData">{this.props.intl.messages['noData']}</div>
                    )}
                    {'LOCATION_HISTORY' === this.state.activeTab &&
                    <LocationHistory
                        selectPoint={this.props.selectPoint}
                        points={points}
                        selectedPoint={this.props.state.points.selectedPoint}
                        intl={this.props.intl}
                        configs={this.props.state.configs}
                        timeZone={this.props.state.load.load && this.props.state.load.load.pickupTimeZone}
                        blocks={this.getBlocks(load)}
                        stops={this.props.state.load.load ? this.props.state.load.load.stops : []}
                        loadId={this.query.loadId}
                        noDataKey="load.noData"
                    />
                    }
                    <button className={"export-button" + (this.isPending(GET_PDF_LOAD_REPORT) ? " pending" : "")}
                            onClick={() => this.getPdfReport()}>
                        <span>{this.props.intl.messages['report.export']}</span>
                    </button>
                </div>
                <ControlPanelAddendum
                    printPdfReport={() => this.getPdfReport(true)}
                    mailPdfReport={(email, callback) => this.mailPdfReport(email, callback)}
                    addMessage={this.props.addMessage}
                    clearMessages={this.props.clearMessages}
                    mailActionType={MAIL_PDF_LOAD_REPORT}
                    printActionType={GET_PDF_LOAD_REPORT_PRINT}
                    pendingActions={this.props.state.pendingActions}
                />
            </>
        );
    }

    getBlocks(load) {
        if (!load || !load.bookingStatuses || !load.bookingStatuses.length) {
            return [];
        }
        let bookingStatusMap = load.bookingStatuses.reduce((a, s) => a.set(s.bookingStatus, moment(s.statusTime)), new Map());
        let result = [];
        if (bookingStatusMap.has('ARRIVED_TO_PICKUP') && bookingStatusMap.has('LOADED')) {
            let start = bookingStatusMap.get('ARRIVED_TO_PICKUP');
            let end = bookingStatusMap.get('LOADED');
            result.push({
                style: "loadingVehicle",
                start: {
                    time: start,
                    status: this.getStatusName(load, 'ARRIVED_TO_PICKUP'),
                    starting: true
                },
                end: {
                    time: end,
                    status: this.getStatusName(load, 'LOADED'),
                    starting: false
                }
            });
        }
        if (bookingStatusMap.has('ARRIVED_TO_DELIVERY')) {
            let start = bookingStatusMap.get('ARRIVED_TO_DELIVERY');
            let end = bookingStatusMap.get('DELIVERED');
            result.push({
                style: "unloadingVehicle",
                start: {
                    time: start,
                    status: this.getStatusName(load, 'ARRIVED_TO_DELIVERY'),
                    starting: true
                },
                end: {
                    time: end,
                    status: this.getStatusName(load, 'DELIVERED'),
                    starting: false
                }
            });
        }
        return result;
    }

    isPending(actionType) {
        return this.props.state.pendingActions.includes(actionType);
    }

    getPdfReport(print) {
        let load = this.props.state.load.load;
        let date = moment().format(this.props.intl.messages['load.bookingStatus.date.format']);
        let reference = this.query.ref || this.query.loadId;
        let dispatchParams = {
            print,
            fileName: this.props.intl.formatMessage({id: "load.report.name"}, {
                reference,
                from: [load.from.town, load.from.postcode].filter(o => o).join(", "),
                to: [load.to.town, load.to.postcode].filter(o => o).join(", "),
                date
            })
        };
        this.props.getPdfLoadReport(load, this.query.loadId, dispatchParams);
    }

    mailPdfReport(email, callback) {
        this.props.mailPdfLoadReport({...this.getLoad(), email}, this.query.loadId, callback);
    }

    getLoad() {
        if (this.query.ref) {
            return {...this.props.state.load.load, reference: this.query.ref};
        }
        return this.props.state.load.load;
    }

    renderEventSummary(load) {
        let events = [];
        events.push(...this.getBookingStatusEvents(events, load));
        events.push(...this.getStopEvents(events, load));
        events.sort((i1, i2) => i1.time - i2.time);
        let result = [];
        let oldDateString = "";
        for (let i = 0; i < events.length; i++) {
            const event = events[i];
            let showDate = oldDateString !== event.dateString;
            oldDateString = event.dateString;
            result.push(<div key={event.key} className="loadSummary-bookingStatusBlock">
                <div className="loadSummary-bookingStatusTime">
                    <div>{event.formattedTime}</div>
                    <div>{showDate && event.dateString}</div>
                </div>
                <div className="loadSummary-bookingStatusText">
                    <div className="loadSummary-bookingStatusName">
                        {event.name}
                    </div>
                    <div className="loadSummary-bookingStatusLocation">{event.location}</div>
                </div>
            </div>);
        }
        return result;
    }

    getStopEvents(result, load) {
        const stops = load.stops;
        if (!stops) {
            return [];
        }
        return stops.filter(s => s.stopStatuses).flatMap(s => {
            return s.stopStatuses.map(stop => {
                const tz = s.timeZoneName;
                const eventTime = stop.statusTime + "Z";
                const dateString = this.formatEventDate(eventTime, tz);
                return {
                    time: moment(eventTime).valueOf(),
                    key: "stopEvent" + s.stopOrder + stop.status,
                    formattedTime: this.formatEventTime(eventTime, tz),
                    tz,
                    dateString,
                    name: `${this.props.intl.messages[`stop.${s.type}.${stop.status}`]}`,
                    location: getStopAddress(s)
                };
            });
        });
    }

    getBookingStatusEvents(result, load) {
        let bookingStatuses = load.bookingStatuses;
        if (!bookingStatuses) {
            return [];
        }
        return bookingStatuses.map(s => {
            const bookingStatus = s.bookingStatus;
            const tz = this.getStatusTz(load, bookingStatus);
            const eventTime = s.statusTime;
            const dateString = this.formatEventDate(eventTime, tz);
            return ({
                time: moment(eventTime).valueOf(),
                key: bookingStatus,
                formattedTime: this.formatEventTime(eventTime, tz),
                tz,
                dateString,
                name: this.getStatusName(load, bookingStatus),
                location: this.getStatusLocation(load, bookingStatus)
            });
        });
    }

    getStatusName(load, status) {
        return this.props.intl.messages['load.bookingStatus.' + status];
    }

    formatEventTime(statusTime, tz) {
        return (moment(statusTime)
            .tz(tz)
            .format(this.props.intl.messages['load.bookingStatus.time.format']));
    }

    formatEventDate(statusTime, tz) {
        return (moment(statusTime)
            .tz(tz)
            .format(this.props.intl.messages['load.bookingStatus.date.format']));
    }

    getStatusLocation(load, bookingStatus) {
        switch (bookingStatus) {
            case "ARRIVED_TO_PICKUP":
            case "LOADED":
                return load.fromDisplayAddress;
            case "ARRIVED_TO_DELIVERY":
            case "DELIVERED":
                return load.toDisplayAddress;
            default:
                return "";
        }
    }

    getStatusTz(load, bookingStatus) {
        switch (bookingStatus) {
            case "ARRIVED_TO_DELIVERY":
            case "DELIVERED":
                return load.deliveryTimeZone || moment.tz.guess();
            default:
                return load.pickupTimeZone || moment.tz.guess();
        }
    }
}

Load.propTypes = {
    getLoadInfo: PropTypes.func.isRequired,
    getPdfLoadReport: PropTypes.func.isRequired,
    mailPdfLoadReport: PropTypes.func.isRequired,
    selectPoint: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired,
    clearMessages: PropTypes.func.isRequired,

    state: PropTypes.object.isRequired,
    location: PropTypes.object,
    intl: PropTypes.object
};

export default connect(state => {
    return {
        state: state
    };
}, dispatch => {
    return bindActionCreators({...Actions}, dispatch);
})(injectIntl(Load));
