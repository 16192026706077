import { GET_CHARITY_USERS, successfulAction } from '../actions/actionTypes';

const initialState = {
    users: [],
};

export default function charityUsers(state = initialState, action) {
    switch (action.type) {
        case successfulAction(GET_CHARITY_USERS): {
            return {
                users: action.payload,
            };
        }
        default:
            return state;
    }
}
