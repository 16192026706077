import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Spinner.css';

class Spinner extends Component {
    render() {
        if (!this.props.visible) {
            return (<span/>);
        }

        return (
            <div className="loading"/>
        );
    }
}

Spinner.propTypes = {
    visible: PropTypes.bool.isRequired
};

export default Spinner;
