const parameters = (typeof window !== 'undefined') && new URLSearchParams(document.location.href);
export const DEFAULT_LANGUAGE = "en";
export const DEFAULT_COUNTRY = "GB";
let cxLocale = parameters && 'default' !== parameters.get("l") && parameters.get("l") || DEFAULT_LANGUAGE;
let country = parameters && 'default' !== parameters.get("c") && parameters.get("c") || DEFAULT_COUNTRY;
let locale;
let language;
if (cxLocale.includes("_")) {
    locale = cxLocale.replace("_", "-");
    language = locale.split("-")[0];
} else {
    locale = cxLocale + "-" + country;
    language = cxLocale;
}

export default {language, locale, country};
