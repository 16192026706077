import React, {Component} from "react";
import PropTypes from 'prop-types';

class ExpandableComponent extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {expanded: props.expandedByDefault};
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.expandedByDefault && null === this.state.expanded) {
            this.setState({expanded: true});
        }
    }

    render() {
        let expanded = this.state.expanded;
        return (<div className={[this.props.className, !expanded ? this.props.expandedClassName : ""].join(" ")}>
            <div className="clickable" onClick={() => this.setState({expanded: !this.state.expanded})}>
                {this.props.content}
                <span className={expanded ? "expandable_arrow expanded" : "expandable_arrow"}/>
            </div>
            {expanded &&
            <div className={this.props.childBlockClassName}>
                {this.props.children}
            </div>
            }
        </div>);
    }
}

ExpandableComponent.propTypes = {
    className: PropTypes.string,
    expandedClassName: PropTypes.string,
    childBlockClassName: PropTypes.string,
    children: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired,
    expandedByDefault: PropTypes.bool
};

export default ExpandableComponent;
