import React, {Component} from 'react';
import {render} from 'react-dom';
import PropTypes from 'prop-types'
import {injectIntl} from 'react-intl';
import END_MARKER_IMAGE from '../assets/images/end_marker.png';
import START_MARKER_IMAGE from '../assets/images/start_marker.png';
import PIN_MARKER_IMAGE from '../assets/images/marker-blue-revert.svg';
import PointInfoWindow from './PointInfoWindow';

const mapId = 'here-map-replay-ui';

const COLORS = [
    'rgba(0, 119, 255, 0.7)',
    'rgba(0, 0, 0, 0.7)',
    'rgba(0, 119, 0, 0.7)',
    'rgba(119, 0, 0, 0.7)',
    'rgba(119, 0, 119, 0.7)',
    'rgba(119, 0, 255, 0.7)',
    'rgba(119, 119, 0, 0.7)',
    'rgba(119, 119, 255, 0.7)',
    'rgba(255, 0, 0, 0.7)',
    'rgba(255, 0, 119, 0.7)',
    'rgba(255, 0, 255, 0.7)',
    'rgba(255, 119, 119, 0.7)',
    'rgba(255, 119, 255, 0.7)',
    'rgba(0, 0, 119, 0.7)',
    'rgba(0, 0, 255, 0.7)',
    'rgba(0, 119, 119, 0.7)',
];

const isDevelopment = process && process.env && process.env.NODE_ENV === 'development';
const apiKeyHere = isDevelopment
    ? 'MjE8duSsbNPkJrVDexI1kQ5w6UfvUdYbiV9qHcazQzY'
    : 'ErgISDqWjHQvQBmbHEfW4UGj8YZOyonsZKVjarVOnuI';

const getColor = (index) => COLORS[index % COLORS.length];

const getCookie = (cname) => {
    const name = `${cname}=`;
    const decodedCookie = isDevelopment
        ? decodeURIComponent(window.document.cookie)
        : decodeURIComponent(window.parent.document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};

const isNaRegion = ['US', 'MX', 'CA']
    .includes((JSON.parse(window.parent.localStorage.getItem('appToken'))
        || {countryCode2: 'GB'}).countryCode2);


const loadHereScript = (scriptName) => new Promise((resolve, reject) => {
    const mapsScript = document.querySelector(`script[src$="/mapsjs-${scriptName}.js"]`);
    if (mapsScript) {
        resolve();
        return;
    }

    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.crossorigin = 'anonymous';
    scriptElement.src = `https://js.api.here.com/v3/3.1/mapsjs-${scriptName}.js`;
    scriptElement.onload = () => resolve();
    scriptElement.onerror = () => reject();
    document.head.appendChild(scriptElement);
});

const RENDERER_TYPES = {WEBGL: 'WEBGL', P2D: 'P2D'};
const currentRenderer = getCookie('h_renderer') || RENDERER_TYPES.WEBGL;


class HereMapComponent extends Component {
    static propTypes = {
        startSpinner: PropTypes.func.isRequired,
        stopSpinner: PropTypes.func.isRequired,
        updateRouteSummary: PropTypes.func.isRequired,
        addMessage: PropTypes.func.isRequired,
        clearMessages: PropTypes.func.isRequired,
        selectPoint: PropTypes.func.isRequired,
        selectPointByCoordinates: PropTypes.func.isRequired,
        registerRoute: PropTypes.func.isRequired,
        onMapLoaded: PropTypes.func.isRequired,
        points: PropTypes.array,
        timeZone: PropTypes.string,
        selectedPoint: PropTypes.object,
        load: PropTypes.object,
        pointsStepMins: PropTypes.number,
        minimumRouteTimeGap: PropTypes.number,
        configs: PropTypes.object,
        location: PropTypes.object,
        intl: PropTypes.object
    };

    componentDidMount() {
        this.geoPointsArr = [];
        this.distance = 0;
        const init = currentRenderer === RENDERER_TYPES.P2D
            ? this.initLegacyMap
            : this.initMap;
        init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.startMarker && this.props.load && this.props.load.from.coordinates) {
            this.createStartMarker(this.props.load.from.coordinates);
            this.props.onMapLoaded();
        }

        if (!this.finishMarker && this.props.load && this.props.load.to.coordinates) {
            this.createFinishMarker(this.props.load.to.coordinates);
            if (!this.props.points || !this.props.points.length) {
                this.boundMapView();
            }

        }

        if (!this.stopMarkerGroup && this.props.load && this.props.load.stops && this.props.load.stops.length) {
            this.createStopMarkerGroup(this.props.load.stops);
            if (!this.props.points || !this.props.points.length) {
                this.boundMapView();
            }

        }

        if (this.map && prevProps.points && prevProps.points.length && this.props.points !== prevProps.points && this.polyline) {
            if (this.polyline === true) {// eslint-disable-line
                return;
            }
            for (let object of this.map.getObjects()) {
                if (object.id === 'polyline' || object.id === 'marker') {
                    this.map.removeObject(object);
                }
            }

            this.geoPointsArr = [];
            this.distance = 0;
            this.polyline = false;
            this.props.updateRouteSummary(0, 0);
        }

        if (this.map && this.props.points && this.props.points.length && !this.polyline) {
            this.polyline = true;
            this.createPolyline();
        }

        if (!prevProps.selectedPoint.point && this.props.selectedPoint.point) {
            this.createPointBalloon(this.props.selectedPoint.point);
            return;
        }

        if (prevProps.selectedPoint.point && !this.props.selectedPoint.point && !this.timerCreatePoint) {
            this.removePointBalloon();
            return;
        }

        if (prevProps.selectedPoint.point && this.props.selectedPoint
            && prevProps.selectedPoint.point.coordinates !== this.props.selectedPoint.point.coordinates) {
            this.removePointBalloon();
            this.createPointBalloon(this.props.selectedPoint.point);
        }
    }

    createStopMarkerGroup = (stops) => {
        const stopMarkerGroup = new H.map.Group({// eslint-disable-line
        });


        const createMarkerStop = (item) => {
            const {lat, lng} = item.address.coordinates;
            const element = document.createElement('div');
            element.style.width = '14px';
            element.style.height = '14px';
            element.style.borderRadius = '50%';
            element.style.border = '4px solid #fff';
            element.style.boxShadow = '0px 0px 5px 1px rgba(0,0,0,0.75)';
            element.style.background = item.type === 'PICKUP' ? '#008000' : '#000';

            element.style.position = 'relative';
            element.style.top = '-8px';

            element.style.left = '-6px';

            const svgIcon = new H.map.DomIcon(element); // eslint-disable-line
            const markerStop = new H.map.DomMarker({lat, lng}, { // eslint-disable-line
                icon: svgIcon,
            });
            this.addPointToBounds(lat, lng);
            return markerStop;
        };


        stops.forEach(item => {
            stopMarkerGroup.addObject(createMarkerStop(item));
        });

        this.stopMarkerGroup = stopMarkerGroup;
        this.map.addObject(this.stopMarkerGroup);
    };

    createPolyline = async () => {
        if (this.polyline && this.polyline !== true) {
            this.map.deleteObject(this.polyline);
        }


        if (this.props.points.length === 1) {
            const lat = this.props.points[0].coordinates.lat + 0.001;
            const lng = this.props.points[0].coordinates.lng + 0.001;

            const secondPoint = {coordinates: {lat, lng}, summary: {distance: 0, baseTime: 0}}


            this.createPoints([[{...this.props.points[0], summary: {distance: 0, baseTime: 0}}, secondPoint]]);
            return;
        }
        const {trackingStoppedEvents = []} = this.props.load || {trackingStoppedEvents: []};
        const preparedPoints = await this.preparePoints(this.props.points, trackingStoppedEvents);
        Promise.all(preparedPoints)
            .then(res => res.filter(i => !!i))
            .then(res => {
                this.createPoints(res);
            });
    };

    createPointBalloon = (point) => {
        const domIcon = this.createDomIconMarker(PIN_MARKER_IMAGE);
        this.pointMarker = new H.map.DomMarker(point.coordinates, {// eslint-disable-line
            icon: domIcon,
            zIndex: 2,
        });
        this.map.addObject(this.pointMarker);
        const pointBalloonData = {content: this.createPointBalloonData(point)};
        const pointBalloon = new H.ui.InfoBubble(point.coordinates, pointBalloonData);// eslint-disable-line
        this.pointBalloon = pointBalloon;
        this.ui.addBubble(pointBalloon);
    };

    removePointBalloon = () => {
        if (this.pointMarker) {
            this.map.removeObject(this.pointMarker);
        }
        if (this.pointBalloon) {
            this.ui.removeBubble(this.pointBalloon);
        }
    };

    calculateRouteFromAtoB = (pointA, pointB, data) => {
        if (!this.router) {
            return;
        }
        const routeRequestParams = {
            mode: 'fastest;car;traffic:disabled',
            representation: 'display',
            waypoint0: `${pointA.lat},${pointA.lng}`,
            waypoint1: `${pointB.lat},${pointB.lng}`,
            routeattributes: 'waypoints,summary,shape',
            maneuverattributes: 'direction,action'
        };
        return new Promise((resolve, reject) => {
            const routingSuccess = results => {
                const route = results.response.route[0];
                resolve(route.shape.map(s => {
                    const [lat, lng] = s.split(',');
                    return {coordinates: {lat, lng}, data, summary: route.summary};
                }));
            };
            const onError = () => {
                resolve(null);
            };
            this.router.calculateRoute(
                routeRequestParams,
                routingSuccess,
                onError
            );
        });

    };

    preparePoints = async (points, trackingStoppedEvents) => {
        const stopEvents = trackingStoppedEvents && trackingStoppedEvents.length
            ? trackingStoppedEvents.map(({dateTime}) => {
                const dotIndex = dateTime.length - dateTime.lastIndexOf('.');
                return Date.parse(dateTime.slice(0, -dotIndex) + 'Z')
            })
            : null;
        const result = await points.map(async (loadedPoint, index) => {
            if (index === points.length - 1) {
                return;
            }
            const {coordinates} = points[index + 1];
            if (stopEvents) {
                if (index === 0) {
                    const pointTime = Date.parse(loadedPoint.time);
                    const nextPointTime = Date.parse((points[index + 1] || {time: null}).time);
                    for (let i = 0; i < stopEvents.length; i++) {
                        if (pointTime < stopEvents[i]) {
                            return;
                        }
                        if (pointTime > stopEvents[i] && nextPointTime < stopEvents[i]) {
                            return;
                        }
                    }

                } else {

                    const pointTime = Date.parse(loadedPoint.time);
                    const nextPointTime = Date.parse((points[index + 1] || {time: null}).time);

                    for (let i = 0; i < stopEvents.length; i++) {
                        if (i === stopEvents.length - 1) {
                            if (pointTime < stopEvents[i] && nextPointTime > stopEvents[i]) {
                                return;
                            }
                        } else {
                            if (pointTime < stopEvents[i] && stopEvents[i] < nextPointTime) {
                                return;
                            }
                        }
                    }
                }
            }

            return await this.calculateRouteFromAtoB({
                lat: loadedPoint.coordinates.lat,
                lng: loadedPoint.coordinates.lng,
            }, coordinates, loadedPoint);
        });
        return result;
    };

    createPoints = (points) => {
        points.forEach((item, index) => {
            const createLine = (arrItems) => {

                const pairsArr = arrItems.map(p => [p.lat, p.lng].join(','));
                this.props.registerRoute(pairsArr.join(','), '#2e7b32', 1234567);

                let lineString = new H.geo.LineString();// eslint-disable-line
                arrItems.forEach(({lat, lng}) => lineString.pushLatLngAlt(lat, lng));

                const polyline = new H.map.Polyline(lineString, {// eslint-disable-line
                    style: {
                        lineWidth: 5,
                        strokeColor: '#2e7b32',
                        strokeOpacity: 0.7
                    }
                });
                polyline.addEventListener('pointerenter', (evt) => {
                    this.map.getViewPort().element.style.cursor = 'pointer';
                    const coord = this.map.screenToGeo(evt.currentPointer.viewportX, evt.currentPointer.viewportY);
                    this.props.selectPointByCoordinates(coord);
                });

                polyline.addEventListener('pointerleave', () => {
                    this.map.getViewPort().element.style.cursor = 'grab';
                });

                polyline.id = 'polyline';

                this.map.addObject(polyline);

                this.map.addEventListener('pointerenter', (evt) => {
                    if (this.pointBalloon
                        && (evt.target instanceof H.ui.InfoBubble || evt.target instanceof H.map.Marker)) { // eslint-disable-line
                        return;
                    }
                    this.props.selectPoint(null);
                });
            };

            const {summary: {distance = 0, baseTime = 0}, coordinates: {lat, lng}} = points[index][0];
            this.addPointToBounds(lat, lng);
            this.distance = this.distance ? this.distance + distance : +distance;
            this.baseTime = this.baseTime ? this.baseTime + baseTime : +baseTime;
            let acum = [];
            let lastDay;

            item.forEach(({coordinates: {lat, lng}, data: {time}}, index) => {

                const day = (new Date(time)).getDate();
                if (index === 0) {
                    lastDay = day;
                }
                acum.push({lat, lng});
                if (lastDay !== day) {
                    createLine(acum, this.props.load ? 'rgba(0, 119, 255, 0.7)' : getColor(lastDay));
                    acum = [];
                    lastDay = day;
                }

            });
            if (acum.length) {
                createLine(acum);
                this.polyline = 1;
                if (!this.props.load && (lastDay && !this.myDay || this.myDay !== lastDay)) {
                    this.myDay = lastDay;
                    this.createStartMarker(acum[0]);
                }
            }
        });
        this.props.updateRouteSummary(this.distance, this.baseTime);
        this.boundMapView();
        if (!this.props.load) {
            const {points: allPoints} = this.props;
            allPoints
                .map((point, index) => (index === allPoints.length - 1
                    || ((new Date(point.time)).getDate() !== (new Date(allPoints[index + 1].time)).getDate()))
                    ? point : false)
                .filter(element => !!element)
                .forEach(({coordinates}) => this.createFinishMarker(coordinates));
        }
    };
    boundMapView = () => {
        const container = new H.map.Group({// eslint-disable-line
            objects: this.geoPointsArr,
        });

        this.map.getViewModel().setLookAtData({
            bounds: container.getBoundingBox(),
        });
    };
    createPointBalloonData = (selectedPoint, tagId = 'infoWindowPoint') => {
        const balloon = document.createElement('div');
        balloon.id = tagId;
        setTimeout(() => {
            const element = document.getElementById(tagId);
            element && render(
                <PointInfoWindow
                    selectedPoint={selectedPoint}
                    intl={this.props.intl}
                    timeZone={this.props.timeZone}
                    showSpeed={true}
                />, element
            );
        }, 0);

        return balloon;
    };

    createDomIconFlag = (image) => {
        const element = document.createElement('img');
        element.src = image;
        element.style.position = 'relative';
        element.style.top = '-24px';
        element.style.width = '21px';
        element.style.height = '21px';
        element.style.left = '0px';
        return new H.map.DomIcon(element);// eslint-disable-line
    };

    createDomIconMarker = (image) => {
        const element = document.createElement('img');
        element.src = image;
        element.style.position = 'relative';
        element.style.top = '-24px';
        element.style.width = '24px';
        element.style.height = '24px';
        element.style.left = '-12px';
        return new H.map.DomIcon(element);// eslint-disable-line
    };

    createStartMarker = (coordinates) => {
        const domIcon = this.createDomIconFlag(START_MARKER_IMAGE);
        this.startMarker = new H.map.DomMarker(coordinates, {// eslint-disable-line
            icon: domIcon,
            zIndex: 2,
        });

        this.startMarker.id = 'marker';
        this.map.addObject(this.startMarker);
        this.addPointToBounds(coordinates.lat, coordinates.lng);
    };

    createFinishMarker = (coordinates) => {
        const domIcon = this.createDomIconFlag(END_MARKER_IMAGE);
        this.finishMarker = new H.map.DomMarker(coordinates, {// eslint-disable-line
            icon: domIcon,
            zIndex: 2,
        });

        this.finishMarker.id = 'marker';
        this.map.addObject(this.finishMarker);
        this.addPointToBounds(coordinates.lat, coordinates.lng);
    };

    addPointToBounds = (lat, lng) => {
        const geoPoint = new H.map.Marker(new H.geo.Point(lat, lng));// eslint-disable-line
        this.geoPointsArr.push(geoPoint);
    };

    createPlatform = () => new H.service.Platform({// eslint-disable-line
        apikey: apiKeyHere,
    });

    getDefaultMapPosition = () => isNaRegion
        ? {center: new window.H.geo.Point(42, -111), zoom: 4.5}
        : {center: new window.H.geo.Point(54, -5), zoom: 6};

    initMap = () => {
        const platform = this.createPlatform();
        const defaultLayers = platform.createDefaultLayers();
        const mapPosition = this.getDefaultMapPosition();
        this.map = new H.Map(document.getElementById(mapId), defaultLayers.raster.normal.map, {// eslint-disable-line
            ...mapPosition,
            pixelRatio: 1
        });
        this.router = platform.getRoutingService();
        this.runMap(defaultLayers);
    };

    loadLegacyScripts = async () => {
        const scriptsToLoad = ['core-legacy', 'service-legacy'];
        /* eslint-disable no-await-in-loop */
        for (let i = 0; i < scriptsToLoad.length; i++) {
            const scriptName = scriptsToLoad[i];
            await loadHereScript(scriptName);
        }
    };

    initLegacyMap = async () => {
        await this.loadLegacyScripts();
        const platform = this.createPlatform();
        const mapPosition = this.getDefaultMapPosition();
        const defaultLayers = platform.createDefaultLayers();
        this.map = new H.Map(document.getElementById(mapId), defaultLayers.raster.normal.map, {// eslint-disable-line
            ...mapPosition,
            pixelRatio: window.devicePixelRatio || 1,
            engineType: window.H.Map.EngineType[RENDERER_TYPES.P2D],
        });
        this.router = platform.getRoutingService();
        this.runMap(defaultLayers);
    };

    runMap = (defaultLayers) => {
        window.addEventListener('resize', () => this.map.getViewPort().resize());
        const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));// eslint-disable-line
        this.ui = H.ui.UI.createDefault(this.map, defaultLayers);// eslint-disable-line
        this.ui.removeControl("mapsettings");


        this.map.addEventListener('pointerdown', (event) => {
            this.map.getViewPort().element.style.cursor = 'grabbing';
        });

        this.map.addEventListener('pointerup', (event) => {
            if (event.target instanceof H.map.DomMarker && !event.target.getData().isSearchMarker) {// eslint-disable-line
                this.map.getViewPort().element.style.cursor = 'pointer';
            } else {
                this.map.getViewPort().element.style.cursor = 'grab';
            }
        });
        this.map.getViewPort().setPadding(32, 32, 32, 400);
    };

    render() {
        return (
            <div className="here-map-react-presenter" style={{flexGrow: 1}} id={mapId}/>
        );
    }
}

export default injectIntl(HereMapComponent);
