/*eslint-disable max-len,quotes*/
export default {
    "popup.close": "Sluiten",
    "report.export": "Export rapport",
    "date.format": "DD MMM YYYY",
    "selectDates.format": "DD MMM YYYY",
    "selectDates.error.rangeTooWide": "Datum bereik mag niet groter zijn dan {maxRangeDays} dagen",
    "lengthUnits.name.mi": "mijlen",
    "lengthUnits.name.km": "kilometer",
    "mapPointPopup.time.format": "HH:mm (z) DD MMM YYYY",
    "speedUnits.name.mph": "m/h",
    "speedUnits.name.kph": "k/h",
    "googleApi.directions.error": "Fout bij het ophalen van routegevens: {status}",

    "myDay.title": "Mijn dag",
    "myDay.selectDates": "Selecteer data:",
    "myDay.report.name": "Rapportage rapport voor het voertuig {reference} op {dateFrom}",
    "myDay.report.name.severalDays": "Rapportage voertuig {reference} op {dateFrom}-{dateTo}",
    "myDay.noData": "Er is geen tracking data te zien",

    "load.title": "Rapportage (<b>Laad ID: {loadId}</b>)",
    "load.bookingStatus.title": "Boeking status",
    "load.bookingStatus.time.format": "HH:mm (z)",
    "load.bookingStatus.date.format": "DD MMM YYYY",
    "load.bookingStatus.ACCEPTED": "Geaccepteerd",
    "load.bookingStatus.DECLINED": "Afgewezen",
    "load.bookingStatus.ARRIVED_TO_PICKUP": "Ter plaatse pick-up",
    "load.bookingStatus.LOADED": "Geladen",
    "load.bookingStatus.ARRIVED_TO_DELIVERY": "Ter plaatse levering",
    "load.bookingStatus.DELIVERED": "Geleverd",
    "load.bookingStatus.EN_ROUTE_TO_PICKUP": "Onderweg naar pick-up",
    "load.locationHistory.title": "Locatie geschiedenis",
    "load.report.name": "Rapportage laad {reference} van {from} naar {to} op {date}",
    "load.noData": `Deze boeking is niet gevolgd
Er is geen tracking data te zien`,

    "email.input.placeholder": "E-mail adres invoeren",
    "email.input.error.notEmpty": "E-mail moet niet leeg zijn",
    "email.input.error.invalid": "Ongeldig e-mailadres",
    "email.sendReport": "E-mail rapport",
    "email.sendReport.error": "Rapport versturen misluktd",
    "email.sendReport.success": "Rapport verstuurd",

    "routeLength.label.mi": "Totaal aantal miles gereisd",
    "routeLength.label.km": "Totaal aantal kilometers gereisd",
    "noData": "Geen gegevens om weer te geven",
    "charityRide.title": "Charity Ride"
};
