import {
    GET_PDF_MY_DAY_REPORT, GET_PDF_LOAD_REPORT,
    GET_PDF_MY_DAY_REPORT_PRINT, GET_PDF_LOAD_REPORT_PRINT,
    MAIL_PDF_MY_DAY_REPORT, MAIL_PDF_LOAD_REPORT,
    startedAction, successfulAction, failedAction
} from '../actions/actionTypes';

let defaultState = [];

const watchedActions = [
    GET_PDF_MY_DAY_REPORT,
    GET_PDF_MY_DAY_REPORT_PRINT,
    MAIL_PDF_MY_DAY_REPORT,
    GET_PDF_LOAD_REPORT,
    GET_PDF_LOAD_REPORT_PRINT,
    MAIL_PDF_LOAD_REPORT
];

const showSpinnerActions = watchedActions.map(item => startedAction(item));
const hideSpinnerActions = [
    ...watchedActions.map(item => successfulAction(item)),
    ...watchedActions.map(item => failedAction(item))
];

function isInActions(action, supportedActions) {
    return (-1 < supportedActions.indexOf(action));
}


export default function pendingActionsReducer(state = defaultState, action = {type: 'UNKNOWN'}) {
    if (isInActions(action.type, showSpinnerActions)) {
        return [...state, action.type.replace(/_STARTED$/, "")];
    }
    if (isInActions(action.type, hideSpinnerActions)) {
        let originalType = action.type.replace(/(_SUCCESS|_FAILURE)$/, "");
        return state.filter(a => a !== originalType);
    }
    return state;
}
