import React, {Component} from "react";
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import ExpandableComponent from "./ExpandableComponent";
import RouteUtils from "../utils/RouteUtils";
import {stopEvent} from "../utils/eventUtils";
import {getStopAddress} from '../utils/stopUtils'

const POINT_CLASS_PREFIX = "point-";

class LocationHistory extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {dates: this.getDateGroups(props)};
    }

    componentWillReceiveProps(nextProps) {
        let shouldUpdateDates = (!this.state.dates
                || !RouteUtils.isSamePointCollection(nextProps.points, this.props.points)
                || this.props.loadId !== nextProps.loadId
        );
        if (shouldUpdateDates) {
            this.setState({dates: this.getDateGroups(nextProps)});
        }
    }

    getDateGroups(nextProps) {
        let eventsInfo = LocationHistory.mergeEventsInfo(
                LocationHistory.convertPointTimeToMoments(nextProps.points),
                nextProps.blocks, nextProps.stops);
        const getTz = () => nextProps.timeZone || moment.tz.guess();
        let hours = LocationHistory.groupPointsIntoHours(eventsInfo, getTz);
        return LocationHistory.groupHoursIntoDates(hours, getTz);
    }

    render() {
        let points = this.props.points;
        return (points && points.length > 1)
                ? (<div className="daysBlock" key="daysBlock" onWheel={e => this.scrollFixed(e)}>
                    {this.getDateItems(this.props.blocks, this.props.configs.splitByHourTreshold)}
                </div>)
                : (<div className="noData" key="noData">
                    {this.renderBreakableText(this.props.intl.messages[this.props.noDataKey])}
                </div>
                );
    }

    scrollFixed(event) {
        const target = event.currentTarget;
        const wheelDeltaY = event.nativeEvent.wheelDeltaY;

        if (wheelDeltaY > 0 && target.scrollTop === 0
                || wheelDeltaY <= 0 && target.scrollTop === target.scrollHeight - target.clientHeight){
            return stopEvent(event);
        }
        return true;
    }

    renderBreakableText(text) {
        return text && text.split("\n").map(item => [item,<br key="text"/>]); // eslint-disable-line react/jsx-key
    }

    getDateItems(blocks, splitByHourTreshold) {
        let dates = this.state.dates;
        let result = [];
        let isFirst = true;
        let collapseHours = !dates.some(date => LocationHistory.getPointLength(date.items) >= splitByHourTreshold);

        for (let dateItem of dates) {
            result.push(this.renderDate(dateItem, isFirst, collapseHours));
            isFirst = false;
        }
        return result;
    }

    static convertPointTimeToMoments(points) {
        let result = [];
        for (let point of points) {
            result.push({...point, time: moment(point.time)});
        }
        return result;
    }

    static mergeEventsInfo(points, blocks, stops) {
        if (!blocks || !blocks.length) {
            return points;
        }
        let result = [];
        for (let point of points) {
            let className = LocationHistory.getPointBlockStyle(point, blocks);
            result.push({...point, className, type: "POINT", classOrder: 3});
        }

        for (let block of blocks) {
            let className =  block.style;
            if (block.start.time) {
                result.push({...block.start, className, type: "STATUS", classOrder: 1});
            }
            if (block.end.time) {
                result.push({...block.end, className, type: "STATUS", classOrder: 1});
            }
        }

        for (let stop of stops) {
            for (let stopStatus of stop.stopStatuses) {
                result.push({
                    time: moment(stopStatus.statusTime + "Z"),
                    stopStatus: stopStatus.status,
                    fullAddress: getStopAddress(stop),
                    stopType: stop.type,
                    className: stop.type === "PICKUP" ? "stop-pickup-icon" : "stop-delivery-icon",
                    type: "STOP",
                    classOrder: 2
                })
            }
        }

        return result.sort((p1, p2) => p1.time.diff(p2.time));
    }

    static getPointBlockStyle(point, blocks) {
        for (let block of blocks) {
            if (moment(point.time).isBetween(block.start.time, block.end.time)) {
                return block.style;
            }
        }
        return null;
    }

    renderDate(date, expanded, collapseHours) {
        return (
                <ExpandableComponent
                        childBlockClassName={collapseHours ? "pointsBlock" : "hoursBlock"}
                        key={date.time}
                        expandedClassName={date.className}
                        expandedByDefault={expanded}
                        content={<span className="time_header">
                            {date.time.format(this.props.intl.messages['selectDates.format'])}
                            {collapseHours && this.renderTz()}
                        </span>}
                >
                    {collapseHours
                        ? this.renderEvents(date.items.map(h => h.items).reduce((a, p) => a.concat(p)))
                        : this.renderHours(date.items)}
                </ExpandableComponent>);
    }

    renderHours(hours) {
        let result = [];
        let isFirst = true;
        for (let hour of hours) {
            result.push(this.renderHour(hour, isFirst));
            isFirst = false;
        }
        return result;
    }

    static getPointLength(hours) {
        let totalPoints = 0;
        for (let hour of hours) {
            totalPoints += hour.items.length;
        }
        return totalPoints;
    }

    renderHour(hour, expanded) {
        return (
                <ExpandableComponent
                        childBlockClassName="pointsBlock"
                        key={hour.time}
                        expandedClassName={hour.className}
                        expandedByDefault={expanded}
                        content={<span>
                            <span className="time_header">
                                <span className="time_header-time">{moment(hour.time).tz(this.getTz()).format('HH:mm')}</span>
                                <span className="time_header-separator">—</span>
                                <span className="time_header-time">{moment(hour.time).add(1, 'hour').tz(this.getTz()).format('HH:mm')}</span>
                            </span>
                            {this.renderTz()}
                        </span>}
                >
                    {this.renderEvents(hour.items)}
                </ExpandableComponent>);
    }

    renderTz() {
        return (<span className="timezone_header"> ({moment.tz(this.getTz()).format('z')})</span>);
    }

    getTz() {
        return this.props.timeZone || moment.tz.guess();
    }

    renderEvents(points) {
        let result = [];
        for (let point of points) {
            result.push(this.renderEvent(point));
        }
        return result;
    }

    renderEvent(event) {
        switch (event.type) {
            case "STATUS":
                return this.renderBookingStatus(event);
            case "STOP":
                return this.renderStop(event);
            default:
                return this.renderPoint(event);
        }
    }

    renderBookingStatus(event) {
        let className = [
            'bookingStatus',
            'point',
            event.className,
            event.starting ? 'bookingStatus-starting' : 'bookingStatus-ending'
        ].join(' ');
        return (<div key={event.status} className={className}>{event.status}</div>);
    }

    renderStop(stop) {
        let className = [
            'point',
            'stop',
        ].join(' ');
        return (
                <div key={stop.time} className={className}>
                    <div className="stop_header">
                        {`${this.props.intl.messages[`stop.${stop.stopType}.${stop.stopStatus}`]}`}
                        <i className={stop.className} />
                    </div>
                    <span className="point_time">{moment(stop.time).tz(this.getTz()).format('HH:mm')}</span>
                    <span className="point_address">{stop.fullAddress}</span>
                </div>);
    }

    renderPoint(point) {
        const selectedPoint = this.props.selectedPoint.point;
        const isSelected = selectedPoint && point.time.isSame(selectedPoint.time);
        let className = [
            isSelected ? 'point-selected' : 'point',
            point.className ? POINT_CLASS_PREFIX + point.className : '',
        ].join(' ');

        const handlerMouseOver = e => {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.props.selectPoint(point, 'LIST');
            }, 100);
            e.preventDefault();
        };

        const handlerMouseLeave= e => {
            clearTimeout(this.timer);
            if (!this.props.selectedPoint.point) {
                return;
            }
            this.props.selectPoint(null);
            e.preventDefault();
        };

        return (
                <div key={point.time}
                        className={className}
                        onMouseOver={handlerMouseOver}
                        onMouseLeave={handlerMouseLeave}
                >
                    <span className="point_time">{moment(point.time).tz(this.getTz()).format('HH:mm')}</span>
                    <span className="point_address">{point.fullAddress}</span>
                </div>);
    }

    static groupPointsIntoHours(items, getTz) {
        const groupingTimeUnit = 'hour';
        return this.groupByTime(items, getTz, groupingTimeUnit);
    }

    static groupHoursIntoDates(items, getTz) {
        const groupingTimeUnit = 'date';
        return this.groupByTime(items, getTz, groupingTimeUnit);
    }

    static groupByTime(items, getTz, groupingTimeUnit) {
        items = [...items].sort((o1, o2) => o1.time.diff(o2.time));
        let result = [];
        let group;
        let className = null;
        let classOrder = Number.MAX_SAFE_INTEGER;
        for (let item of items) {
            const intervalStart = moment(item.time).tz(getTz()).startOf(groupingTimeUnit);
            if (group && group.time.tz(getTz()).isSame(intervalStart)) {
                if (item.classOrder < classOrder) {
                    className = item.className;
                    classOrder = item.classOrder;
                }
                group.items.push(item);
            } else {
                if (group) {
                    group.className = className;
                    group.classOrder = classOrder;
                    result.push(group);
                }
                className = item.className;
                classOrder = item.classOrder;
                group = { time: intervalStart, items: [item] };
            }
        }
        if (group) {
            group.className = className;
            group.classOrder = classOrder;
            result.push(group);
        }
        return result;
    }
}

LocationHistory.propTypes = {
    selectPoint: PropTypes.func.isRequired,

    points: PropTypes.array.isRequired,
    blocks: PropTypes.array,
    stops: PropTypes.array,
    loadId: PropTypes.string,
    configs: PropTypes.object.isRequired,
    selectedPoint: PropTypes.object,
    timeZone: PropTypes.string,
    noDataKey: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired
};

export default LocationHistory;
