import React, {Component} from "react";
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import * as Actions from '../../actions/actions';
import {GET_PDF_MY_DAY_REPORT, GET_PDF_MY_DAY_REPORT_PRINT, MAIL_PDF_MY_DAY_REPORT}  from '../../actions/actionTypes';
import {DateRangePicker} from 'react-dates';
import '../../assets/styles/datepicker.css';
import RouteSummary from "../../components/RouteSummary";
import LocationHistory from "../../components/LocationHistory";
import ControlPanelAddendum from "../../components/ControlPanelAddendum";
import {isIE} from '../../utils/browserUtils';
import queryString from "query-string";


const MAX_RANGE_DAYS = 31;

function getEndDate(newState) {
    return !newState.endDate || (newState.startDate > newState.endDate)
            ? moment(newState.startDate)
            : moment(newState.endDate);
}

class Home extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = this.getState(props);
    }

    componentWillMount() {
        this.query = queryString.parse(this.props.location.search);
        this.getPoints(this.state);
    }

    getState(props) {
        return {
            startDate: props.startDate || moment(),
            endDate: props.endDate || moment(),
            focusedInput: null
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (null === this.state.focusedInput && this.state.dirty) {
            this.getPoints(this.state);
            this.setState({dirty: false}); // eslint-disable-line react/no-did-update-set-state
        }
    }

    updateDates(nextState) {
        if (this.state.startDate !== nextState.startDate
                || this.state.endDate !== nextState.endDate) {
            this.setState({...nextState, dirty: true});
        }
    }

    validate(state) {
        this.props.clearMessages();
        if (state.endDate.diff(state.startDate, 'days') > MAX_RANGE_DAYS) {
            this.props.addMessage(({
                key: "selectDates.error.rangeTooWide",
                data: {maxRangeDays: MAX_RANGE_DAYS},
                type: "ERROR"
            }));
            return false;
        }
        return true;
    }

    getPoints(state) {
        if (!this.validate(state)) {
            return;
        }

        let searchParameters = this.getSearchParameters(state);
        let minimumRouteTimeGap = this.query.debug
                ? Infinity
                : searchParameters.stepMins * this.props.state.configs.routeTimeGapCoefficient;

        this.props.getPoints(searchParameters, minimumRouteTimeGap);
        this.props.selectPoint(null);
    }

    getSearchParameters(state) {
        let { debug = false } = this.query;
        let momentTimeFrom = moment(state.startDate).startOf('day');
        let timeFrom = momentTimeFrom.toISOString();
        let momentTimeTo = moment(state.endDate).endOf('day');
        let timeTo = momentTimeTo.toISOString();
        let durationMins = moment.duration(momentTimeTo.valueOf() - momentTimeFrom.valueOf()).as('minutes');
        let stepMins = Math.max(Math.ceil(durationMins / this.props.state.configs.maxTotalWaypoints), this.props.state.configs.minStep);

        let searchParameters = {
            timeFrom,
            timeTo
        };
        if (debug) {
            searchParameters.debug = true;
        } else {
            searchParameters.stepMins = stepMins;
        }
        if (this.query.ref) {
            searchParameters.reference = this.query.ref;
        }
        searchParameters.vehicleId = this.query.vehicleId;

        return searchParameters;
    }

    renderAdditionalCalendarPanel = () => {
        return <div className="selectDates-close" onClick={() => this.setState({focusedInput: null})}/>;
    };

    render() {
        const points = this.props.state.points.points;
        let countryCode = points && points.length && points[0].countryCode;
        return (
                <div>
                    <div className={"control-panel" + (isIE() ? " control-panel-ie-fix" : "")}>
                        <div className="panel-title">
                            <FormattedMessage id="myDay.title"/>
                        </div>
                        <div className="search-block">
                            <label>
                                <FormattedMessage id="myDay.selectDates"/>
                            </label>
                            <DateRangePicker startDate={this.state.startDate} endDate={this.state.endDate}
                                    onDatesChange={(e) => this.updateDates({
                                        startDate: moment(e.startDate || this.state.startDate),
                                        endDate: getEndDate(e)
                                    })}
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={(e) => this.setState({focusedInput: e})}
                                    showDefaultInputIcon numberOfMonths={1}
                                    minimumNights={0}
                                    customArrowIcon=" — "
                                    customInputIcon={<svg width="13" height="14" viewBox="0 0 13 14" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.333 6.3H2.89v1.4h1.444V6.3zm2.89 0H5.777v1.4h1.444V6.3zm2.888 0H8.667v1.4h1.444V6.3zm1.445-4.9h-.723V0H9.39v1.4H3.61V0H2.167v1.4h-.723C.643 1.4.007 2.03.007 2.8L0 12.6c0 .77.643 1.4 1.444 1.4h10.112C12.35 14 13 13.37 13 12.6V2.8c0-.77-.65-1.4-1.444-1.4zm0 11.2H1.444V4.9h10.112v7.7z" fill="#555" fillRule="evenodd"/>
                                    </svg>}
                                    navPrev="<"
                                    navNext=">"
                                    displayFormat={this.props.intl.messages['selectDates.format']}
                                    isOutsideRange={(d) => false}
                                    renderCalendarInfo={this.renderAdditionalCalendarPanel}
                            />
                        </div>
                        <RouteSummary routes={this.props.state.routes} intl={this.props.intl} countryCode={countryCode} />
                        <LocationHistory
                                selectPoint={this.props.selectPoint}
                                points={points}
                                selectedPoint={this.props.state.points.selectedPoint}
                                intl={this.props.intl}
                                configs={this.props.state.configs}
                                noDataKey="myDay.noData"
                        />
                        <button className={"export-button" + (this.isPending(GET_PDF_MY_DAY_REPORT) ? " pending" : "")}
                                onClick={() => this.getPdfReport()}>
                            <span>{this.props.intl.messages['report.export']}</span>
                        </button>
                    </div>
                    <ControlPanelAddendum
                            printPdfReport={() => this.getPdfReport(true)}
                            mailPdfReport={(email, callback) => this.mailPdfReport(email, callback)}
                            addMessage={this.props.addMessage}
                            clearMessages={this.props.clearMessages}
                            mailActionType={MAIL_PDF_MY_DAY_REPORT}
                            printActionType={GET_PDF_MY_DAY_REPORT_PRINT}
                            pendingActions={this.props.state.pendingActions}
                    />
                </div>
                );
    }

    isPending(actionType) {
        return this.props.state.pendingActions.includes(actionType);
    }

    getPdfReport(print) {
        let reference = this.query.ref || this.query.vehicleId;
        let isSingleDate = this.state.startDate.isSame(this.state.endDate, 'day');
        let dispatchParams = {
            print,
            fileName: this.props.intl.formatMessage({id: isSingleDate ? "myDay.report.name" : "myDay.report.name.severalDays"}, {
                reference,
                dateFrom: this.state.startDate.format(this.props.intl.messages['date.format']),
                dateTo: this.state.endDate.format(this.props.intl.messages['date.format'])
            })
        };
        this.props.getPdfMyDayReport(this.getSearchParameters(this.state), dispatchParams);
    }

    mailPdfReport(email, callback) {
        this.props.mailPdfMyDayReport({...this.getSearchParameters(this.state), email}, callback);
    }
}

Home.propTypes = {
    state: PropTypes.object.isRequired,
    getPoints: PropTypes.func.isRequired,
    getPdfMyDayReport: PropTypes.func.isRequired,
    mailPdfMyDayReport: PropTypes.func.isRequired,
    selectPoint: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired,
    clearMessages: PropTypes.func.isRequired,
    location: PropTypes.object,
    intl: PropTypes.object
};

const e = connect(state => {
    return {
        state: state
    };
}, dispatch => {
    return bindActionCreators({...Actions}, dispatch);
})(injectIntl(Home));

export default e;
