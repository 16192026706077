import {combineReducers} from 'redux';
import points from './points';
import configs from './configs';
import spinner from './spinner';
import routes from './routes';
import messages from './messages';
import load from "./load";
import pendingActions from "./pendingActions";
import charityUsers from "./charityUsers";

const rootReducer = combineReducers({
    points,
    configs,
    messages,
    spinner,
    routes,
    load,
    pendingActions,
    charityUsers,
});

export default rootReducer;
