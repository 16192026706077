/*eslint-disable max-len,quotes*/
export default {
    "popup.close": "Schließen",
    "report.export": "Bericht exportieren",
    "date.format": "DD MMM YYYY",
    "selectDates.format": "DD MMM YYYY",
    "selectDates.error.rangeTooWide": "Datumsbereich sollte nicht größer sein als {maxRangeDays} tage",
    "lengthUnits.name.mi": "meilen",
    "lengthUnits.name.km": "kilometer",
    "mapPointPopup.time.format": "HH:mm (z) DD MMM YYYY",
    "speedUnits.name.mph": "mph",
    "speedUnits.name.kph": "kph",
    "speed.average": " (average)",
    "googleApi.directions.error": "Fehler beim Abfragen der Routendaten: {status}",

    "myDay.title": "Mein Tag",
    "myDay.selectDates": "Daten auswählen:",
    "myDay.report.name": "Replay-Fahrzeug {reference} am {dateFrom}",
    "myDay.report.name.severalDays": "Replay-Fahrzeug {reference} am {dateFrom}-{dateTo}",
    "myDay.noData": "Es gibt keine Tracking-Daten zum Anzeigen",

    "load.title": "Replay (<b>Ladungsreferenznummer: {loadId}</b>)",
    "load.bookingStatus.title": "Buchungsstatus",
    "load.bookingStatus.time.format": "HH:mm (z)",
    "load.bookingStatus.date.format": "DD MMM YYYY",
    "load.bookingStatus.ACCEPTED": "Akzeptiert",
    "load.bookingStatus.DECLINED": "Abgelehnt",
    "load.bookingStatus.ARRIVED_TO_PICKUP": "Vor Ort (Abholung)",
    "load.bookingStatus.LOADED": "Geladen",
    "load.bookingStatus.ARRIVED_TO_DELIVERY": "Vor Ort (Lieferung)",
    "load.bookingStatus.DELIVERED": "Geliefert",
    "load.bookingStatus.EN_ROUTE_TO_PICKUP": "Auf dem Weg zum Abholort",
    "load.locationHistory.title": "Standortverlauf",
    "load.report.name": "Replay-Ladung {reference} von {from} nach {to} am {date}",
    "load.noData": `Diese Buchung wurde nicht verfolgt
Es gibt keine Tracking-Daten zum Anzeigen`,
    "stop.type.PICKUP": "Abholung stoppen",
    "stop.type.DELIVERY": "Liefer Stopp",
    "stop.status.STOP_SKIPPED": "Übersprungen",
    "stop.status.STOP_COMPLETED": "Ausgeführt",

    "email.input.placeholder": "Bitte Ihre E-Mail-Adresse eingeben",
    "email.input.error.notEmpty": "E-Mail sollte nicht leer sein",
    "email.input.error.invalid": "Ungültige E-Mail-Adresse",
    "email.sendReport": "E-Mail-Bericht",
    "email.sendReport.error": "Fehler beim Übermitteln des Berichts",
    "email.sendReport.success": "Bericht übermittelt",

    "routeLength.label.mi": "Gesamte zurückgelegte Meilen",
    "routeLength.label.km": "Gesamte zurückgelegte Kilometer",
    "noData": "Keine Daten zum Anzeigen",
    "charityRide.title": "Charity Ride"
};
