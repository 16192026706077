import React, {Component} from "react";
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import RouteUtils from "../utils/RouteUtils";
import locale from "../utils/locale";

class PointInfoWindow extends Component {
    i18n(key) {
        return this.props.intl.messages[key];
    }

    formatCoordinate(coordinate) {
        return this.props.intl.formatNumber(coordinate, {maximumFractionDigits: 6});
    }

    formatSpeed(speed) {
        return this.props.intl.formatNumber(speed, {maximumFractionDigits: 1});
    }

    render() {
        let selectedPoint = this.props.selectedPoint;
        let time = (moment(selectedPoint.time)
                .tz(this.getTz())
                .format(this.i18n('mapPointPopup.time.format')));
        let address = selectedPoint.fullAddress;
        let lat = this.formatCoordinate(selectedPoint.coordinates.lat);
        let lng = this.formatCoordinate(selectedPoint.coordinates.lng);
        let speedUnit = RouteUtils.getSpeedUnit(locale.country);
        return (<div className="pointInfo-container">
            <p>{time}</p>
            <p className="pointInfo-address">{address}</p>
            <p>{lat}, {lng}</p>
            {!!selectedPoint.speedKmh && this.props.showSpeed &&
            <p>
                <svg className="pointInfo-speedIcon" width="23" height="13" viewBox="0 0 23 13" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.064 8.053c.323-.217.755-.109.97.252.216.325.108.759-.251.975L12.9 11.555c0 .795-.646 1.445-1.437 1.445a1.445 1.445 0 0 1 0-2.889c.287 0 .503.072.719.18l3.881-2.238zM11.5 0C5.175 0 0 5.164 0 11.556v.061a.711.711 0 0 0 .732.702l1.568-.041a.723.723 0 0 0 .709-.846c-.06-.355-.394-.599-.753-.599H1.51c.108-1.336.431-2.564 1.006-3.72l.72.434a.691.691 0 0 0 .359.109.695.695 0 0 0 .61-.362.702.702 0 0 0-.251-.975l-.719-.397A10.014 10.014 0 0 1 5.93 3.25l.395.722c.144.217.36.361.61.361a.69.69 0 0 0 .36-.108c.36-.217.468-.65.252-.975l-.396-.686a10.209 10.209 0 0 1 3.63-.975v.859c0 .36.243.696.596.755a.721.721 0 0 0 .842-.711v-.903c1.33.108 2.552.433 3.702.975l-.396.686a.702.702 0 0 0 .251.975.692.692 0 0 0 .36.108.695.695 0 0 0 .611-.36l.395-.687a10.414 10.414 0 0 1 2.66 2.672l-.683.398c-.36.216-.468.65-.252.975.144.216.36.36.611.36a.691.691 0 0 0 .36-.108l.683-.397c.539 1.12.898 2.383 1.005 3.72h-.757c-.341 0-.66.224-.734.559a.722.722 0 0 0 .583.873h1.598a.71.71 0 0 0 .712-.703v-.007C22.964 5.164 17.825 0 11.5 0z" fill="#555" fillRule="evenodd"/>
                </svg>
                {this.formatSpeed(speedUnit.coefficient * selectedPoint.speedKmh)}
                {this.i18n("speedUnits.name." + speedUnit.nameKeySuffix)}
                {this.i18n("speed.average")}
            </p>
            }
        </div>);
    }

    getTz() {
        return this.props.timeZone || moment.tz.guess();
    }

}

PointInfoWindow.propTypes = {
    selectedPoint: PropTypes.object.isRequired,
    timeZone: PropTypes.string,
    showSpeed: PropTypes.bool,
    intl: PropTypes.object.isRequired
};

export default PointInfoWindow;
