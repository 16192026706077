import React, {Component} from "react";
import PropTypes from 'prop-types';
import RouteUtils from "../utils/RouteUtils";
import locale from "../utils/locale";

class RouteSummary extends Component {
    formatLength(length, lengthUnit) {
        return Math.round(length * lengthUnit.coefficient);
    }

    render() {
        let lengthUnit = RouteUtils.getLengthUnit(locale.country);
        return (this.props.routes.length !== null)
                ? (<div className="route-summary">
                    <svg width="23" height="7" viewBox="0 0 23 7" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.035 3a3.5 3.5 0 1 1 .302 2H6.663a3.5 3.5 0 1 1 .302-2H8V0h1v3h2V1h1v2h2V0h1v3h1.035zM3.5 5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm16 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" fillRule="nonzero" fill="#555"/>
                    </svg>

                    <span className="routeSummary-length">
                        <span className="routeSummary-length-label">{this.props.intl.messages['routeLength.label.' + lengthUnit.nameKeySuffix]}:</span>
                        {this.formatLength(this.props.routes.length, lengthUnit)}
                    </span>
                </div>)
                : (<div/>);
    }
}

RouteSummary.propTypes = {
    routes: PropTypes.object.isRequired,
    countryCode: PropTypes.string,
    intl: PropTypes.object.isRequired
};

export default RouteSummary;
