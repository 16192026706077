import React from 'react';
import ReactDOM from 'react-dom';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import nl from 'react-intl/locale-data/nl';
import de from 'react-intl/locale-data/de';
import ru from 'react-intl/locale-data/ru';
import './index.css';
import Main from './Main';
import * as serviceWorker from './serviceWorker';

addLocaleData(en);
addLocaleData(ru);
addLocaleData(nl);
addLocaleData(de);

ReactDOM.render(<Main />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
