import React, {Component} from "react";
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';

class ControlPanelAddendum extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {email: ""};
    }

    render() {
        return (
                <div className="controlPanelAddendum">
                    <input className="controlPanelAddendum-input"
                            value={this.state.email}
                            ref={i => this.email = i}
                            placeholder={this.props.intl.messages['email.input.placeholder']}
                            required
                            pattern="[^@]+@[^@]+\.[^.@]+"
                            onChange={e => this.setState({email: e.target.value})}/>
                    <button className={"mailButton" + (this.isPending(this.props.mailActionType) ? " pending" : "")}
                            onClick={() => this.mailPdfReport()}>
                        {this.props.intl.messages['email.sendReport']}
                    </button>
                    <button className={"printButton" + (this.isPending(this.props.printActionType) ? " pending" : "")}
                            onClick={() => this.props.printPdfReport(true)}/>
                </div>
        );
    }

    mailPdfReport() {
        this.props.clearMessages();
        if (this.email.checkValidity()) {
            this.email.classList.remove("invalid");
            this.props.mailPdfReport(this.state.email, () => this.setState({email: ""}));
        } else {
            this.email.classList.add("invalid");
            if (this.email.validity.valueMissing) {
                this.props.addMessage({key: "email.input.error.notEmpty", type: "ERROR"});
            } else if (this.email.validity.patternMismatch) {
                this.props.addMessage({key: "email.input.error.invalid", type: "ERROR"});
            }
        }
    }

    isPending(actionType) {
        return this.props.pendingActions.includes(actionType);
    }
}

ControlPanelAddendum.propTypes = {
    printPdfReport: PropTypes.func.isRequired,
    mailPdfReport: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired,
    clearMessages: PropTypes.func.isRequired,

    mailActionType: PropTypes.string.isRequired,
    printActionType: PropTypes.string.isRequired,
    pendingActions: PropTypes.array.isRequired,
    intl: PropTypes.object
};

export default injectIntl(ControlPanelAddendum);
