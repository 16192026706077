import {REGISTER_ROUTE, UPDATE_ROUTE_SUMMARY, GET_POINTS, successfulAction} from '../actions/actionTypes';

const initialState = {
    length: null,
    time: null,
    lastResetTime: null,
    polylines: []
};


export default function routes(state = initialState, action) {
    switch (action.type) {
        case UPDATE_ROUTE_SUMMARY:
            return Object.assign({}, state, {...action.payload});
        case successfulAction(GET_POINTS):
            return initialState;
        case REGISTER_ROUTE:
            if (action.payload.queryTime < state.lastResetTime) {
                return state;
            }
            if (!state.lastResetTime || action.payload.queryTime > state.lastResetTime) {
                return Object.assign({}, state, {
                    lastResetTime: action.payload.queryTime,
                    polylines: [{polyline: action.payload.polyline, color: action.payload.color}]
                });
            } else {
                return Object.assign({}, state, {
                    polylines: [...state.polylines, {polyline: action.payload.polyline, color: action.payload.color}]
                });
            }
        default:
            return state;
    }
}
