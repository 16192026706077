import moment from 'moment-timezone';

const mph = {
    coefficient: 0.621371,
    nameKeySuffix: "mph"
};
const kph = {
    coefficient: 1,
    nameKeySuffix: "kph"
};
const mile = {
    coefficient: 0.000621371,
    nameKeySuffix: "mi"
};
const km = {
    coefficient: 0.001,
    nameKeySuffix: "km"
};
const mileCountries = ["US", "GB", "IM", "GG", "JE"];

class RouteUtils {
    static isSamePoint(p1, p2) {
        return p1 && p2 && (p1.time === p2.time) || !p1 && !p2;
    }

    static splitToRoutes(points, minimumRouteTimeGap) {
        let routes = [[]];
        let routeNumber = 0;
        let previousPointTimeMs = null;
        for (let point of points) {
            const currentPointTimeMs = moment(point.time).valueOf();
            if (previousPointTimeMs) {
                if (moment.duration(currentPointTimeMs - previousPointTimeMs).as('minutes') > minimumRouteTimeGap) {
                    routeNumber++;
                    routes.push([]);
                }
            }
            previousPointTimeMs = currentPointTimeMs;
            routes[routeNumber].push(point);
        }
        return routes;
    }

    static simplifyRoute(originalRoute, lastIndexRestriction, nearbyThresholdDegrees) {
        if (!originalRoute.length) {
            return [];
        }

        let route = RouteUtils.filterNearbyCoordinates(originalRoute, nearbyThresholdDegrees);
        return this.restrictPointCount(route, lastIndexRestriction);
    }

    static restrictPointCount(route, lastIndexRestriction) {
        let lastIndex = route.length - 1;
        let result = [];
        result.push(route[0]);
        const maxIndex = Math.min(lastIndex, lastIndexRestriction);
        const step = lastIndex / maxIndex;
        for (let i = 1; i < maxIndex; i++) {
            result.push(route[Math.round(i * step)]);
        }
        result.push(route[lastIndex]);
        return result;
    }

    static filterNearbyCoordinates(route, nearbyThresholdDegrees) {
        return route.reduce((a, p) => {
            if (!a.length || RouteUtils.areFarEnough(a[a.length - 1], p, nearbyThresholdDegrees)) {
                p.repeats = 0;
                a.push(p);
            } else {
                a[a.length - 1].repeats++;
            }
            return a;
        }, []);
    }

    static filterNearbyPoints(route, nearbyThresholdDegrees) {
        return route.reduce((a, p) => {
            if (!a.length || RouteUtils.areFarEnough(a[a.length - 1].coordinates, p.coordinates, nearbyThresholdDegrees)) {
                p.repeats = 0;
                a.push(p);
            } else {
                a[a.length - 1].repeats++;
            }
            return a;
        }, []);
    }

    static areFarEnough(p1, p2, nearbyThresholdDegrees) {
        return Math.abs(p1.lng - p2.lng) > nearbyThresholdDegrees || Math.abs(p1.lat - p2.lat) > nearbyThresholdDegrees;
    }

    static getSpeedUnit(countryCode) {
        return mileCountries.includes(countryCode) ? mph : kph;
    }

    static getLengthUnit(countryCode) {
        return mileCountries.includes(countryCode) ? mile : km;
    }

    static isSameLoad(l1, l2) {
        return l1 && l2 && (JSON.stringify(l1) === JSON.stringify(l2)) || !l1 && !l2;
    }

    static isSamePointCollection(p1, p2) {
        return p1 && p2
                && p1.length === p2.length
                && (!p1.length ||
                        p2[0].time === p1[0].time
                        && p2[p2.length - 1].time === p1[p1.length - 1].time
                );
    }
}

export default RouteUtils;
