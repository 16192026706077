/*eslint-disable max-len,quotes*/
export default {
    "popup.close": "Close",
    "report.export": "Export Report",
    "date.format": "DD MMM YYYY",
    "selectDates.format": "DD MMM YYYY",
    "selectDates.error.rangeTooWide": "Date range should not be greater than {maxRangeDays} days",
    "lengthUnits.name.mi": "miles",
    "lengthUnits.name.km": "kilometres",
    "mapPointPopup.time.format": "HH:mm (z) DD MMM YYYY",
    "speedUnits.name.mph": "mph",
    "speedUnits.name.kph": "kph",
    "speed.average": " (average)",
    "googleApi.directions.error": "Error when retrieving route data: {status}",

    "myDay.title": "My Day",
    "myDay.selectDates": "Select Dates:",
    "myDay.report.name": "Replay Vehicle {reference} on {dateFrom}",
    "myDay.report.name.severalDays": "Replay Vehicle {reference} on {dateFrom}-{dateTo}",
    "myDay.noData": "There isn’t any tracking data to display",

    "load.title": "Replay (<b>Load ID: {loadId}</b>)",
    "load.bookingStatus.title": "Booking Statuses",
    "load.bookingStatus.time.format": "HH:mm (z)",
    "load.bookingStatus.date.format": "DD MMM YYYY",
    "load.bookingStatus.ACCEPTED": "Accepted",
    "load.bookingStatus.DECLINED": "Declined",
    "load.bookingStatus.ARRIVED_TO_PICKUP": "On Site (Pickup)",
    "load.bookingStatus.LOADED": "Loaded",
    "load.bookingStatus.ARRIVED_TO_DELIVERY": "On Site (Delivery)",
    "load.bookingStatus.DELIVERED": "Delivered",
    "load.bookingStatus.EN_ROUTE_TO_PICKUP": "On my way to Pickup",
    "load.locationHistory.title": "Location History",
    "load.report.name": "Replay Load {reference} from {from} to {to} on {date}",
    "load.noData": `This booking was not tracked
There isn’t any tracking data to display`,
    "stop.type.PICKUP": "Pickup Stop",
    "stop.type.DELIVERY": "Delivery Stop",
    "stop.status.STOP_SKIPPED": "Skipped",
    "stop.status.STOP_COMPLETED": "Completed",
    "stop.PICKUP.STOP_SKIPPED": "Pickup Stop Skipped",
    "stop.PICKUP.STOP_COMPLETED": "Pickup Stop Completed",
    "stop.DELIVERY.STOP_SKIPPED": "Delivery Stop Skipped",
    "stop.DELIVERY.STOP_COMPLETED": "Delivery Stop Completed",
    "stop.PICKUP.STOP_ON_SITE": "Arrived Pickup Stop",
    "stop.DELIVERY.STOP_ON_SITE": "Arrived Delivery Stop",

    "email.input.placeholder": "Enter Your Email",
    "email.input.error.notEmpty": "Email should not be empty",
    "email.input.error.invalid": "Invalid email address",
    "email.sendReport": "Email Report",
    "email.sendReport.error": "Report sending failed",
    "email.sendReport.success": "Report sent",

    "routeLength.label.mi": "Total Miles Driven",
    "routeLength.label.km": "Total Kilometres Driven",
    "noData": "No data to display",
    "charityRide.title": "Defenders 4 Kidneys",
    "charityRide.date.today": "Today",
    "charityRide.date.start": "From The Start",
    "charityRide.hospital.visited": "Visited",
    "charityRide.hospital.arriving": "Arriving",
    "charityRide.hospital.eta": "ETA",
};
