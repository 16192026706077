import React, {Component} from "react";
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';

class MessageStack extends Component {
    render() {
        return (<div className="message_stack">
            {this.renderMessages()}
        </div>);
    }

    renderMessages() {
        if (this.props.messages) {
            let result = [];
            for (let i = 0; i< this.props.messages.length; i++) {
                let message = this.props.messages[i];
                result.push(this.renderMessage(message, i));
            }
            return result;
        } else {
            return null;
        }
    }

    renderMessage(message, index) {
        let text = this.props.intl.formatMessage({id: message.key}, message.data);
        return (
                <div key={`message-${index}`}
                        className={"message_stack_item message_stack_item-" + message.type.toLowerCase()}>
                    {text + (message.count > 1 ? ` (${message.count})` : "" )}
                </div>
        );
    }
}

MessageStack.propTypes = {
    messages: PropTypes.array,
    intl: PropTypes.object
};

export default injectIntl(MessageStack);
