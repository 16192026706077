import React from 'react';
import {Provider} from 'react-redux';
import { Switch, Route, Redirect } from 'react-router'
import {HashRouter} from 'react-router-dom';
import {IntlProvider} from 'react-intl';
import * as moment from "moment";
import configureStore from './store/configureStore';
import * as i18n from './i18n';
// import routes from './routes';
import locale, {DEFAULT_LANGUAGE} from './utils/locale';
import App from './containers/app/App';
import Home from './containers/home/Home';
import Load from './containers/load/Load';

const store = configureStore();

function Main() {
    moment.locale(locale.locale);

    function getMessages(locale) {
        const language = locale.language;
        const localeInternal = locale.locale.replace("-", "_");
        return Object.assign({}, i18n[DEFAULT_LANGUAGE], i18n[language], i18n[localeInternal]);
    }

    function getSupportedLocale(locale) {
        if (i18n[locale.locale]) {
            return locale.locale;
        } else if (i18n[locale.language]) {
            return locale.language;
        } else {
            return DEFAULT_LANGUAGE;
        }
    }

    return (
        <Provider store={store}>
            <IntlProvider key="intl" locale={getSupportedLocale(locale)} messages={getMessages(locale)}>
                <HashRouter>
                    <Switch component={App}>
                        <Route exact path="/" component={props => (<App {...props}> <Load {...props} /> </App>)}/>
                        <Route exact path="/load" component={props => (<App {...props}> <Load {...props} /> </App>)}/>
                        <Route exact path="/my_day" component={props => (<App {...props}> <Home {...props} /> </App>)}/>
                        <Redirect to="/my_day" />
                    </Switch>
                </HashRouter>
            </IntlProvider>
        </Provider>

    );
}

export default Main;
