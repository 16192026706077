import { SPINNER_ACTION, GET_POINTS, GET_LOAD_INFO,
    startedAction, successfulAction, failedAction } from '../actions/actionTypes';

let defaultState = false;

const spinnerActions = [
    SPINNER_ACTION,
    GET_POINTS,
    GET_LOAD_INFO
];

const showSpinnerActions = spinnerActions.map(item => startedAction(item));
const hideSpinnerActions = [
    ...spinnerActions.map(item => successfulAction(item)),
    ...spinnerActions.map(item => failedAction(item))
];

function isInActions(action, supportedActions) {
    return (-1 < supportedActions.indexOf(action));
}


export default function spinnerReducer(state = defaultState, action = {type: 'UNKNOWN'}) {
    if (isInActions(action.type, showSpinnerActions)) {
        return true;
    }
    if (isInActions(action.type, hideSpinnerActions)) {
        return false;
    }
    return state;
}
