import { GET_POINTS, SELECT_POINT, SELECT_POINT_BY_COORDINATES, GET_LOAD_INFO, MAP_LOADED,
    GET_CHARITY_POINTS,
    startedAction, failedAction, successfulAction } from '../actions/actionTypes';
import moment from 'moment';

const initialState = {
    points: [],
    selectedPoint: {},
    loading: true
};

function getPoints(state, points, stepMins, minimumRouteTimeGap) {
    const result = Object.assign({}, state, {
        points,
        stepMins,
        minimumRouteTimeGap,
        loading: false
    });
    return (typeof window !== 'undefined' && window.google && window.google.maps && window.google.maps.geometry) ? calcSpeed(result) : result;
}

function calcSpeed(state) {
    let points = [];
    for (let i = 0; i < state.points.length; i++) {
        const point = Object.assign({}, state.points[i]);
        if (!point.speedKmh) {
            if (0 === i) {
                point.speedKmh = 0;
            } else {
                point.speedKmh = getSpeedKmh(point, points[i-1]);
            }
        }
        points.push(point);
    }
    return Object.assign({}, state, {
        points
    });
}

function getSpeedKmh (point, prevPoint) {
    const distanceKm = distance(point.coordinates, prevPoint.coordinates) / 1000;
    const time = (moment(point.time).unix() - moment(prevPoint.time).unix()) / 60 / 60;
    return distanceKm / time;
}

function distance(coord1, coord2) {
    const point1 = new H.geo.Point(coord1.lat, coord1.lng); // eslint-disable-line no-undef
    const point2 = new H.geo.Point(coord2.lat, coord2.lng); // eslint-disable-line no-undef
    return point1.distance(point2);
}

function createLatLng(coord) {
    return new window.google.maps.LatLng({
        lat: coord.lat,
        lng: coord.lng
    });
}

function measureDistance(coordinates, coordinates2) {
    //TODO: will fail on -180/180 in Pacific, not to be used on ships
    const latDiff = coordinates.lat - coordinates2.lat;
    const lngDiff = coordinates.lng - coordinates2.lng;
    return latDiff * latDiff + lngDiff * lngDiff;
}

function findPoint(points, coordinates) {
    let minDistanceMeasure = Infinity;
    let candidatePoint = null;
    for(let point of points) {
        const distanceMeasure = measureDistance(point.coordinates, coordinates);
        if (minDistanceMeasure > distanceMeasure) {
            minDistanceMeasure = distanceMeasure;
            candidatePoint = point;
        }
    }
    return candidatePoint;
}

export default function points(state = initialState, action) {
    switch (action.type) {
        case startedAction(GET_POINTS):
        case startedAction(GET_CHARITY_POINTS):
        case startedAction(GET_LOAD_INFO):
            return Object.assign({}, state, {loading: true});
        case failedAction(GET_POINTS):
        case failedAction(GET_CHARITY_POINTS):
        case failedAction(GET_LOAD_INFO):
            return Object.assign({}, state, {loading: false});
        case successfulAction(GET_POINTS):
        case successfulAction(GET_CHARITY_POINTS):
            return getPoints(state, action.payload, action.requestParams.stepMins, action.dispatchParams.minimumRouteTimeGap);
        case successfulAction(GET_LOAD_INFO):
            return getPoints(state, action.payload.points, 0, Infinity);
        case SELECT_POINT:
            return Object.assign({}, state, {selectedPoint: action.payload});
        case SELECT_POINT_BY_COORDINATES:
            return Object.assign({}, state, {selectedPoint: {point: findPoint(state.points, action.payload)}});
        case MAP_LOADED:
            return calcSpeed(state);
        default:
            return state;
    }
}
