export const SPINNER_ACTION = 'SPINNER_ACTION';
export const GET_POINTS = 'GET_POINTS';
export const GET_LOAD_INFO = 'GET_LOAD_INFO';
export const GET_PDF_LOAD_REPORT = 'GET_PDF_LOAD_REPORT';
export const GET_PDF_LOAD_REPORT_PRINT = 'GET_PDF_LOAD_REPORT_PRINT';
export const MAIL_PDF_MY_DAY_REPORT = 'MAIL_PDF_MY_DAY_REPORT';
export const GET_PDF_MY_DAY_REPORT = 'GET_PDF_MY_DAY_REPORT';
export const GET_PDF_MY_DAY_REPORT_PRINT = 'GET_PDF_MY_DAY_REPORT_PRINT';
export const MAIL_PDF_LOAD_REPORT = 'MAIL_PDF_LOAD_REPORT';
export const SELECT_POINT = 'SELECT_POINT';
export const SELECT_POINT_BY_COORDINATES = 'SELECT_POINT_BY_COORDINATES';
export const GET_CONFIG = 'GET_CONFIG';
export const UPDATE_ROUTE_SUMMARY = 'UPDATE_ROUTE_SUMMARY';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export const REGISTER_ROUTE = 'REGISTER_ROUTE';
export const MAP_LOADED = 'MAP_LOADED';

export const GET_CHARITY_POINTS = 'GET_CHARITY_POINTS';
export const GET_CHARITY_USERS = 'GET_CHARITY_USERS';

//---------------- Helpers ----------------
export function startedAction(type) {
    return type + '_STARTED';
}

export function successfulAction(type) {
    return type + '_SUCCESS';
}

export function failedAction(type) {
    return type + '_FAILURE';
}

export function isStarted(type) {
    return type.endsWith('_STARTED');
}

export function isSuccess(type) {
    return type.endsWith('_SUCCESS');
}

export function isFailure(type) {
    return type.endsWith('_FAILURE');
}
