import queryString from 'query-string';
import {startedAction, successfulAction, failedAction} from '../../actions/actionTypes';
import locale from "../../utils/locale";
require('es6-promise').polyfill();
require('isomorphic-fetch');

const xsrfCookieRegexp = /xsrf_token_cookie=([^;]+)/;

export function sendApiRequest(path, type, actionType, dispatch, requestParams, dispatchParams, noAuthHeaders) {
    sendRequest(`/replay/v1/${path}`, type, actionType, dispatch, requestParams, dispatchParams, !!noAuthHeaders);
}

export function sendCharityApiRequest(path, type, actionType, dispatch, requestParams, dispatchParams, noAuthHeaders) {
    sendRequest(`/replay/charity-rides/v1/${path}`, type, actionType, dispatch, requestParams, dispatchParams, !!noAuthHeaders);
}

export function sendSharedApiRequest(path, type, actionType, dispatch, requestParams, dispatchParams, noAuthHeaders) {
    sendRequest(`/cxshared/v1/${path}`, type, actionType, dispatch, requestParams, dispatchParams, !!noAuthHeaders);
}


export function sendCxApiRequest(path, type, actionType, dispatch, requestParams, dispatchParams, noAuthHeaders) {
    sendRequest(`/replayapi/v1/${path}`, type, actionType, dispatch, requestParams, dispatchParams, !!noAuthHeaders);
}

export function sendResourceRequest(path, type, actionType, dispatch, requestParams, dispatchParams) {
    sendRequest(`/replay-ui/${path}`, type, actionType, dispatch, requestParams, dispatchParams);
}


function sendRequest(path, type, actionType, dispatch, requestParams, dispatchParams, noAuthHeaders, extraHeaders) {
    dispatch && dispatch({
        type: startedAction(actionType),
        requestParams: requestParams,
        dispatchParams: dispatchParams
    });

    let fullURL = path;
    let fetchOptions = {
        method: type,
        headers: {}
    };

    fetchOptions.headers['Accept-Language'] = locale.locale;

    const xsrfCookieSearchResults = xsrfCookieRegexp.exec(document.cookie);
    fetchOptions.headers['X-XSRF-TOKEN'] = xsrfCookieSearchResults && xsrfCookieSearchResults[1];

    if (extraHeaders) {
        fetchOptions.headers = Object.assign({}, fetchOptions.headers, extraHeaders);
    }

    if (requestParams) {
        if (type === 'post' || type === 'put' || type === 'patch') {
            fetchOptions.body = JSON.stringify(requestParams);
            fetchOptions.headers['Content-Type']= 'application/json';
        } else {
            fullURL = `${fullURL}?${queryString.stringify(requestParams)}`;
        }
    }

    sendFetchRequest(fullURL, fetchOptions, actionType, dispatch, requestParams, dispatchParams);
}

export function sendFetchRequest(url, options, actionType, dispatch, requestParams, dispatchParams) {
    if (!options.headers) {
        options.headers = {};
    }
    options.headers['pragma'] = 'no-cache';
    options.headers['cache-control'] = 'no-cache';
    options.credentials = 'include';

    fetch(url, options)
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response;
            } else {
                let error = new Error(response.statusText);
                error.response = response;
                error.status = response.status;
                throw error;
            }
        })
        .then(response => {
            return response.text();
        })
        .then(text => {
            try {
                return JSON.parse(text);
            } catch (err) {
                return null;
            }
        })
        .then(data => {
            dispatch && dispatch({
                type: successfulAction(actionType),
                payload: data,
                requestParams: requestParams,
                dispatchParams: dispatchParams
            });
        })
        .catch(error => {
            dispatch && dispatch({
                type: failedAction(actionType),
                error: error,
                status: error.status,
                requestParams: requestParams,
                dispatchParams: dispatchParams
            });
        });

}
