import {
    sendApiRequest,
    sendResourceRequest,
    sendCharityApiRequest,
    sendSharedApiRequest,
} from "./requestHelper";
import {
    GET_CONFIG,
    GET_LOAD_INFO,
    GET_PDF_LOAD_REPORT,
    GET_PDF_LOAD_REPORT_PRINT,
    MAIL_PDF_LOAD_REPORT,
    GET_PDF_MY_DAY_REPORT,
    GET_PDF_MY_DAY_REPORT_PRINT,
    MAIL_PDF_MY_DAY_REPORT,
    GET_POINTS,
    GET_CHARITY_POINTS,
    GET_CHARITY_USERS,
    startedAction,
    failedAction,
    successfulAction
} from "../../actions/actionTypes";
import {addMessage, clearMessages} from "../../actions/actions";
import moment from "moment-timezone";
import { saveAs } from 'file-saver';
import RouteUtils from "../../utils/RouteUtils";
import locale from "../../utils/locale";

function getPoints(action, dispatch) {
    let requestParams = {
        timeFrom: action.payload.timeFrom,
        timeTo: action.payload.timeTo,
        debug: action.payload.debug
    };
    sendApiRequest(`vehicles/${action.payload.vehicleId}/points`, 'get', action.type, dispatch, requestParams, action.dispatchParams);
}

function getCharityPoints(action, dispatch) {
    let requestParams = {
        timeFrom: action.payload.timeFrom,
        timeTo: action.payload.timeTo,
    };
    sendCharityApiRequest(`vehicles/${action.payload.vehicleId}/points`, 'get', action.type, dispatch, requestParams, action.dispatchParams);
}

function getCharityUsers(action, dispatch) {
    sendSharedApiRequest(`charity/users`, 'get', action.type, dispatch, action.payload, action.dispatchParams);
}

function getStep(load, maxTotalWaypoints) {
    let timeFrom = load.bookingStatuses[0].statusTime;
    let timeTo = load.bookingStatuses[load.bookingStatuses.length - 1].statusTime;
    let durationMins = moment.duration(moment(timeTo).valueOf() - moment(timeFrom).valueOf()).as('minutes');
    return Math.ceil(durationMins / maxTotalWaypoints);
}

function getPdfLoadReport(action, dispatch, configs, polylines, points, load, length) {
    let {maxTotalWaypoints, nearbyThresholdDegrees} = configs;
    let requestParams = {
        step: getStep(action.payload.load, maxTotalWaypoints),
        polylines: reducePolylines(polylines),
        length,
        points: RouteUtils.filterNearbyPoints(points, nearbyThresholdDegrees),
        load
    };
    getPdfReport(requestParams, `load/${action.payload.loadId}`, action.type, dispatch, action.dispatchParams);
}

function reducePolylines(polylines) {
    const newLines = [];
    polylines.forEach((line) => {
        if (!newLines.length) {
            newLines.push(line);
            return;
        }

        if (newLines[newLines.length - 1].color === line.color) {
            newLines[newLines.length - 1].polyline = newLines[newLines.length - 1].polyline + "," + line.polyline;
            console.log('newLines[newLines.length - 1].polyline', newLines[newLines.length - 1].polyline);
            return;
        }

        newLines.push(line);
    });

    return newLines;
}

function getPdfMyDayReport(action, dispatch, configs, polylines, points, length) {
    let {nearbyThresholdDegrees} = configs;
    let requestParams = {
        ...action.payload,
        polylines: reducePolylines(polylines),
        length,
        points: RouteUtils.filterNearbyPoints(points, nearbyThresholdDegrees),
        timeZone: moment.tz.guess()
    };
    if (requestParams.vehicleId) {
        requestParams.vehicleId = [requestParams.vehicleId];
    }
    getPdfReport(requestParams, `myDay`, action.type, dispatch, action.dispatchParams);
}

function mailPdfLoadReport(action, dispatch, configs, polylines, points, load, length) {
    let {maxTotalWaypoints, nearbyThresholdDegrees} = configs;
    let requestParams = {
        step: getStep(action.payload.load, maxTotalWaypoints),
        polylines: reducePolylines(polylines),
        length,
        displayLengthUnits: getCountryLenghtUnit(),
        points: RouteUtils.filterNearbyPoints(points, nearbyThresholdDegrees),
        load,
        email: action.payload.load.email,
        reference: action.payload.load.reference
    };
    sendApiRequest(`report/load/${action.payload.loadId}/mail`, 'post', action.type, dispatch, requestParams, action.dispatchParams);
}

function mailPdfMyDayReport(action, dispatch, configs, polylines, points, length) {
    let {nearbyThresholdDegrees} = configs;
    let requestParams = {
        ...action.payload,
        polylines: reducePolylines(polylines),
        length,
        displayLengthUnits: getCountryLenghtUnit(),
        points: RouteUtils.filterNearbyPoints(points, nearbyThresholdDegrees),
        timeZone: moment.tz.guess()
    };
    if (requestParams.vehicleId) {
        requestParams.vehicleId = [requestParams.vehicleId];
    }
    if (requestParams.driverId) {
        requestParams.driverId = [requestParams.driverId];
    }
    sendApiRequest(`report/myDay/mail`, 'post', action.type, dispatch, requestParams, action.dispatchParams);
}

function getCountryLenghtUnit() {
    return RouteUtils.getLengthUnit(locale.country).nameKeySuffix.toUpperCase();
}

function getPdfReport(requestParams, reportType, actionType, dispatch, dispatchParams) {
    let {print, fileName} = dispatchParams;
    requestParams.displayLengthUnits = getCountryLenghtUnit();
    dispatch && dispatch({
        type: startedAction(actionType),
        requestParams: requestParams
    });
    let headers = {
        'Content-Type': 'application/json',
        'Accept-Language': locale.locale
    };
    fetch(`/replay/v1/report/${reportType}/pdf`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(requestParams),
        headers
    })
            .then(response => {
                return response.blob();
            })
            .then(blob => {
                if (print) {
                    let contentBody = URL.createObjectURL(blob);
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        //IE doesn't open blobs in windows and often does not understand data URLs properly
                        window.navigator.msSaveOrOpenBlob(blob);
                    } else {
                        let wnd = window.open(contentBody);
                        wnd.print();
                    }
                } else {
                    saveAs(blob, fileName.replace(/[.~#%&*{}:<>?/+|"\\]/g, "_"));
                }
                dispatch && dispatch({
                    type: successfulAction(actionType),
                    requestParams: requestParams
                });
            })
            .catch(error => {
                dispatch && dispatch({
                    type: failedAction(actionType),
                    error: error,
                    status: error.status,
                    requestParams: requestParams
                });
            });
}

function getLoadInfo(action, dispatch) {
    let requestParams = {debug: !!action.payload.debug};
    sendApiRequest(`loads/${action.payload.loadId}`, 'get', action.type, dispatch, requestParams);
}

function getConfig(action, dispatch) {
    sendResourceRequest('config.json', 'get', action.type, dispatch, null, action.payload);
}

export default reduxStore => next => action => {
    switch (action.type) {
        case GET_POINTS:
            getPoints(action, reduxStore.dispatch);
            break;
        case GET_CHARITY_POINTS: {
            getCharityPoints(action, reduxStore.dispatch);
            break;
        }
        case GET_CHARITY_USERS: {
            getCharityUsers(action, reduxStore.dispatch);
            break;
        }
        case GET_CONFIG:
            getConfig(action, reduxStore.dispatch);
            break;
        case GET_LOAD_INFO:
            getLoadInfo(action, reduxStore.dispatch);
            break;
        case GET_PDF_LOAD_REPORT:
        case GET_PDF_LOAD_REPORT_PRINT:
            getPdfLoadReport(action,
                    reduxStore.dispatch,
                    reduxStore.getState().configs,
                    reduxStore.getState().routes.polylines,
                    reduxStore.getState().points.points,
                    reduxStore.getState().load.load,
                    reduxStore.getState().routes.length
            );
            break;
        case GET_PDF_MY_DAY_REPORT:
        case GET_PDF_MY_DAY_REPORT_PRINT:
            getPdfMyDayReport(action,
                    reduxStore.dispatch,
                    reduxStore.getState().configs,
                    reduxStore.getState().routes.polylines,
                    reduxStore.getState().points.points,
                    reduxStore.getState().routes.length
            );
            break;
        case MAIL_PDF_LOAD_REPORT:
            mailPdfLoadReport(action,
                    reduxStore.dispatch,
                    reduxStore.getState().configs,
                    reduxStore.getState().routes.polylines,
                    reduxStore.getState().points.points,
                    reduxStore.getState().load.load,
                    reduxStore.getState().routes.length
            );
            next(action);
            break;
        case MAIL_PDF_MY_DAY_REPORT:
            mailPdfMyDayReport(action,
                    reduxStore.dispatch,
                    reduxStore.getState().configs,
                    reduxStore.getState().routes.polylines,
                    reduxStore.getState().points.points,
                    reduxStore.getState().routes.length
            );
            next(action);
            break;
        case startedAction(MAIL_PDF_LOAD_REPORT):
        case startedAction(MAIL_PDF_MY_DAY_REPORT):
            reduxStore.dispatch(clearMessages());
            next(action);
            break;
        case failedAction(MAIL_PDF_LOAD_REPORT):
        case failedAction(MAIL_PDF_MY_DAY_REPORT):
            reduxStore.dispatch(addMessage({
                key: "email.sendReport.error",
                type: "ERROR"
            }));
            next(action);
            break;
        case successfulAction(MAIL_PDF_LOAD_REPORT):
        case successfulAction(MAIL_PDF_MY_DAY_REPORT):
            reduxStore.dispatch(addMessage({
                key: "email.sendReport.success",
                type: "SUCCESS"
            }));
            action.dispatchParams.callback && action.dispatchParams.callback();
            next(action);
            break;
        case successfulAction(GET_CONFIG):
            next(action);
            action.dispatchParams.successCallback && action.dispatchParams.successCallback();
            break;
        default:
            next(action);
    }
};
