import { ADD_MESSAGE, CLEAR_MESSAGES } from '../actions/actionTypes';

const initialState = [];


function addMessage(array, item) {
    let itemIndex = array.findIndex(element => element.key === item.key
            && element.type === item.type
            && JSON.stringify(element.data) === JSON.stringify(item.data)
    );
    if (itemIndex < 0) {
        return [{...item, count: 1}, ...array];
    } else {
        let result = [...array];
        result[itemIndex] = {...result[itemIndex], count: result[itemIndex].count + 1};
        return result;
    }
}

export default function points(state = initialState, action) {
    switch (action.type) {
        case ADD_MESSAGE:
            return addMessage(state, action.payload);
        case CLEAR_MESSAGES:
            return [];
        default:
            return state;
    }
}
