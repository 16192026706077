import { GET_CONFIG, failedAction, successfulAction } from '../actions/actionTypes';

const initialState = {
    key: "key=AIzaSyAaCsmTBz6by-h-cmaV12nnOdcKtRrA1cY",
    maxWaypoints: 23,
    maxRoadsApiPoints: 100,
    maxTotalWaypoints: 500,
    routeTimeGapCoefficient: 10,
    routeTimeGapCoefficientForLoads: null,
    minStep: 5,
    splitByHourTreshold: 50,
    directionRequestDelayMs: 500,
    nearbyThresholdDegrees: 0.005,
    loaded: false,
    discardThreshold: 0.5
};

function getConfig(state, action) {
    return Object.assign({}, state, action.payload, {loaded: true});
}


export default function configs(state = initialState, action) {
    switch (action.type) {
        case successfulAction(GET_CONFIG):
            return getConfig(state, action);
        case failedAction(GET_CONFIG):
            return getConfig(state, action);
        default:
            return state;
    }
}
